import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import actions from './actions';
import statusTypes from './types';
import { Clients, BankRecords } from '../../api';
import actionsRBI from '../registerBankInvoice/actions';

export function* getClients(param = { payload: null }) {
  try {
    const { payload } = param;
    if (payload != null) {
      const { data } = yield call(Clients.getClientByGroupId, payload);

      if (data.success) {
        const { clients } = data;
        const clientsFrmt = clients.map((cl) => ({ value: cl.id, label: ` ${cl.wurth_id} - ${cl.name}` }));
        yield put(actions.setClientsSuccess(clientsFrmt));
      } else {
        yield put(actions.setClientsFail('Ha ocurrido un error'));
      }
    }
  } catch (e) {
    yield put(actions.setClientsFail('Ha ocurrido un error'));
  }
}

export function* getAccountStatus(param = { payload: null, cb: () => {} }) {
  try {
    const { payload, cb } = param;
    if (payload != null) {
      const { data } = yield call(Clients.getAccountStatus, payload);

      if (data.success) {
        const response = yield call(BankRecords.getBankRecordsWithPositiveBalanceByClientGroup, {
          client_group_id: payload.groupID
        });
        
        if (response.data && response.data.success) {

          if (response.data.internalReceipts.length > 0) {
            const internalReceiptWithPositiveBalance = response.data.internalReceipts[0];
    
            let positiveBalance = 0;
            if (internalReceiptWithPositiveBalance.bankRecord) {
              positiveBalance = internalReceiptWithPositiveBalance.bankRecord.available_amount;
            }
            if (internalReceiptWithPositiveBalance.sellerReceipt) {
              positiveBalance = internalReceiptWithPositiveBalance.sellerReceipt.available_balance;
            }
            data.positiveBalance = positiveBalance;
            data.datePositiveBalance = internalReceiptWithPositiveBalance.createdAt;
          }
        }

        cb({ ...payload, ...data });

        yield put(actions.setAccountSuccess(data));

      } else {
        
        yield call(toast.error, 'Ocurrió un error generando el estado de cuenta.', {
          position: toast.POSITION.BOTTOM_LEFT
        });

        yield put(actions.setAccountFail('Ha ocurrido un error'));
      }
    }
  } catch (e) {
    yield call(toast.error, 'Ocurrió un error generando el estado de cuenta.', {
      position: toast.POSITION.BOTTOM_LEFT
    });
    yield put(actions.setAccountFail('Ha ocurrido un error'));
  }
}

export function* downloadAccountStatusPDF(param = { payload: null }) {
  const { payload } = param;
  try {
    
    const { data } = yield call(Clients.generateAccountStatusPdf, payload);

    if (data && data.success) {
      const { fileName, clientFileName } = data;
      const dataBlob = yield call(Clients.downloadAccountStatusPDF, fileName);
      if (dataBlob) {
        const url = window.URL.createObjectURL(new Blob([dataBlob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', clientFileName);
        if (document.body) document.body.appendChild(link);
        link.click();
        
        yield call(toast.success, 'El estado de cuenta en PDF se descargó con éxito.', {
          position: toast.POSITION.BOTTOM_LEFT
        });
        yield put(actions.getAccountStatusPDFSuccess());
      }
    }
    else {
      yield call(toast.error, 'Ocurrió un error descargando el estado de cuenta en PDF.', {
        position: toast.POSITION.BOTTOM_LEFT
      });
      yield put(actions.getAccountStatusPDFFail());
    }
  } catch (error) {
    yield call(toast.error, 'Ocurrió un error descargando el estado de cuenta en PDF.', {
      position: toast.POSITION.BOTTOM_LEFT
    });
    yield put(actions.getAccountStatusPDFFail());
  }
}

export function* sendMail({ payload }) {
  try {
    const { accountStatusFilters, receivers, subject, content } = payload;
    const { data: dataPDF } = yield call(Clients.generateAccountStatusPdf, accountStatusFilters);
    if (dataPDF && dataPDF.success) {
      const { fileName, clientFileName } = dataPDF;
      const { data } = yield call(Clients.sendMail, { receivers, subject, content, attachment: fileName, clientFileName });
  
      if (data.success) {
        yield put(actions.sendMailSuccess());
      } else {
        yield put(actions.sendMailFailed());
      }
    }
    else {
      yield put(actions.sendMailFailed());
    }
   
  } catch (e) {
    yield put(actions.sendMailFailed());
  }
}

export function* getClientsData(param = { payload: null }) {
  try {
    const { payload } = param;
    if (payload != null) {
      const { data } = yield call(Clients.getClientByGroupId, payload.id);

      if (data.success) {
        const { clients } = data;
        yield put(actionsRBI.addState({ clientName: clients? clients[0].name : '', }));

        if (payload.files !== undefined && payload.files !== null) {
          const formdata = payload.files.split(",");
          const dataFiles = yield call(BankRecords.getFiles, {files: formdata});
          if (dataFiles.data.success) {
            yield put(actionsRBI.addState({ files: dataFiles.data.files? dataFiles.data.files : '', }));
          }
        }

      } else {
        yield put(actions.setClientsFail('Ha ocurrido un error'));
      }
    }
  } catch (e) {
    yield put(actions.setClientsFail('Ha ocurrido un error'));
  }
}

export default function* accountSaga() {
  yield takeLatest(statusTypes.SET_CLIENTS_REQUEST, getClients);
  yield takeLatest(statusTypes.SET_ACCOUNT_REQUEST, getAccountStatus);
  yield takeLatest(statusTypes.GET_ACCOUNT_STATUS_PDF_REQUEST, downloadAccountStatusPDF);
  yield takeLatest(statusTypes.SEND_MAIL_REQUEST, sendMail);
  yield takeLatest(statusTypes.SET_CLIENTS_DATA_REQUEST, getClientsData);
}
