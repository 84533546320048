import statusTypes from './types';

export const initialState = {
  clients: [],
  isLoading: false,
  selectedGroupClient: null,
  sendMailSuccess: null,
  isLoadingGenerateReport: false,
  errorMessage: null
};

export default (state = initialState, action = { type: '', payload: undefined }) => {
  switch (action.type) {
    case statusTypes.CLEAR_ACCOUNT_STATE: {
      return { ...initialState };
    }
    case statusTypes.SET_ACCOUNT_CLIENT_FORM_STATE: {
      return { ...initialState, ...action.payload };
    }
    case statusTypes.SET_CLIENTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        errorMessage: null
      };
    }
    case statusTypes.SET_CLIENTS_SUCCESS: {
      return {
        ...state,
        clients: action.payload,
        isLoading: false,
        errorMessage: null
      };
    }
    case statusTypes.SET_CLIENTS_FAIL: {
      return { ...state, errorMessage: action.payload, isLoading: false };
    }
    case statusTypes.SET_ACCOUNT_REQUEST: {
      return {
        ...state,
        isLoadingGenerateReport: true,
        errorMessage: null
      };
    }
    case statusTypes.SET_ACCOUNT_SUCCESS: {
      // asClientGroup => account status client group
      return {
        ...state,
        isLoadingGenerateReport: false,
        asClientGroup: action.payload.clientGroup,
        asClient: action.payload.client !== undefined ? action.payload.client : null,
        deferredCheckDebt: action.payload.deferred_check_debt,
        movements: action.payload.movements,
        balance: action.payload.balance,
        initialClientAmount: action.payload.initialClientAmount,
        finalAvailableAmount: action.payload.finalAvailableAmount,
        positiveBalance: action.payload.positiveBalance? action.payload.positiveBalance : null,
        datePositiveBalance: action.payload.datePositiveBalance? action.payload.datePositiveBalance : null,
        errorMessage: null
      };
    }
    case statusTypes.SET_ACCOUNT_FAIL: {
      return {
        ...state,
        isLoadingGenerateReport: false,
        errorMessage: action.payload,
        asClientGroup: null,
        asClient: null,
        deferredCheckDebt: null,
        movements: null,
        balance: null,
        initialClientAmount: null,
        finalAvailableAmount: null
      };
    }
    case statusTypes.SEND_MAIL_SUCCESS: {
      return {
        ...state,
        sendMailSuccess: true
      };
    }
    case statusTypes.SEND_MAIL_FAILED: {
      return {
        ...state,
        sendMailSuccess: false
      };
    }
    case statusTypes.GET_ACCOUNT_STATUS_PDF_REQUEST: {
      return {
        ...state,
        isLoadingGenerateReport: true
      };
    }
    case statusTypes.GET_ACCOUNT_STATUS_PDF_SUCCESS:
    case statusTypes.GET_ACCOUNT_STATUS_PDF_FAIL: {
      return {
        ...state,
        isLoadingGenerateReport: false
      };
    }
    case statusTypes.RESET_THE_ACCOUNT_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};
