import axios from '../axiosConfig';

export default {
  uploadCobranzas: (request) => axios.post(`/collector-report/upload`, request),
  uploadRecords: (request) => axios.post(`/bank-records/upload`, request),
  getRecords: (request) => axios.get(`/bank-records/filter`, request),
  sendRecords: (request) => axios.get(`/bank-records/sendRecords`, request),
  generateAdenda: (request) => axios.post(`/internal-receipts/generateAdendaOfInternalReceipt`, request),
  sendConfirmation: (request) => axios.post(`/internal-receipts/createInternalReceipt`, request),
  sendFiles: (request) => axios.post(`/files-manager/upload`, request),
  deleteRecord: (request) => axios.post(`/bank-records/delete`, request),
  getBankInvoices: (request) => axios.post(`/internal-receipts/filter`, request),
  getPreReceipts: (request) => axios.post(`/internal-receipts/getPreReceipt`, request),
  sendReceiptsFiltering: (request) => axios.post(`/internal-receipts/sendReceiptsFiltering`, request),
  getFiles: (request) => axios.post(`/files-manager/get-files`, request),
  cancelInternalReceipt: (request) => axios.post(`/internal-receipts/cancelInternalReceipt`, request),
  createSellerReceipt: (request) => axios.post(`/internal-receipts/createSellerReceipt`, request),
  emitInternalReceipt: (request) => axios.post(`/internal-receipts/emitInternalReceipt`, request),
  modifyBankInternalReceipt: (request) => axios.post(`/internal-receipts/modifyBankInternalReceipt`, request),
  removeAmountInternalReceipt: (request) => axios.post(`/internal-receipts/removeAmountInternalReceipt`, request),
  declineSellerReceipt: (request) => axios.post(`/internal-receipts/declineSellerReceipt`, request),
  shareInternalReceipt: (request) => axios.post(`/internal-receipts/shareInternalReceipt`,request),
  shareInvoice: (request) => axios.post(`/internal-receipts/shareInvoice`,request),
  checkAndEmit: (request) => axios.post(`/internal-receipts/checkAndEmit`, request),

  emitBulkInternalReceipt: (request) => axios.post(`/internal-receipts/emitBulkInternalReceipt`, request),
  rejectBulkInternalReceipt: (request) => axios.post(`/internal-receipts/declineBulkSellerReceipt`, request),
  getBankRecordsWithPositiveBalance: (request) =>
    axios.get('/bank-records/getBankRecordsWithPositiveBalance', { params: request }),
  getBankRecordsWithPositiveBalanceByClientGroup: (request) =>
    axios.get('/bank-records/getBankRecordsWithPositiveBalanceByClientsGroupId', { params: request }),
  checkValidRegisterTime: (request) =>
    axios.get('/internal-receipts/checkValidRegisterTime', { params: request })
};
