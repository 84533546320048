import statusTypes from './types';

export default {
  uploadRecords: (payload) => ({
    type: statusTypes.BANK_RECORD_UPLOAD_REQUEST,
    payload
  }),
  uploadCobranza: (payload) => ({
    type: statusTypes.BANK_RECORD_UPLOAD_COBRANZA,
    payload
  }),
  uploadCobranzaSuccess: (payload) => ({
    type: statusTypes.BANK_RECORD_COBRANZA_SUCCESS,
    payload
  }),
  uploadCobranzaFailed: (payload) => ({
    type: statusTypes.BANK_RECORD_COBRANZA_FAILED,
    payload
  }),
  uploadRecordsSuccess: (payload) => {
    return {
      type: statusTypes.BANK_RECORD_SUCCESS_UPLOAD,
      payload
    };
  },
  uploadRecordsFailed: (payload) => ({
    type: statusTypes.BANK_RECORD_FAILED_UPLOAD,
    payload
  }),
  getRecords: (payload) => ({
    type: statusTypes.GET_BANK_RECORDS_REQUEST,
    payload
  }),
  getRecordsSuccess: (payload) => ({
    type: statusTypes.SUCCESS_GET_BANK_RECORDS,
    payload
  }),
  getRecordsFails: (payload) => ({
    type: statusTypes.FAILED_GET_BANK_RECORDS,
    payload
  }),
  sendRecords: (payload) => ({
    type: statusTypes.SEND_BANK_RECORDS_REQUEST,
    payload
  }),
  sendRecordsSuccess: (payload) => ({
    type: statusTypes.SUCCESS_SEND_BANK_RECORDS,
    payload
  }),
  sendRecordsFails: (payload) => ({
    type: statusTypes.FAILED_SEND_BANK_RECORDS,
    payload
  }),
  loading: () => ({
    type: statusTypes.LOADING
  }),
  getClients: (payload) => ({
    type: statusTypes.CLIENT_REQUEST,
    payload
  }),
  getClientsSuccess: (payload) => {
    return {
      type: statusTypes.CLIENT_REQUEST_SUCCESS,
      payload
    };
  },
  getClientsFailed: (payload) => ({
    type: statusTypes.CLIENT_REQUEST_FAILED,
    payload
  }),
  resetState: () => ({
    type: statusTypes.RESET_STATE
  }),
  sendConfirmation: (payload) => ({
    type: statusTypes.SEND_CONFIRMATION,
    payload
  }),
  confirmationSuccess: (payload) => ({
    type: statusTypes.CONFIRMATION_SUCCESS,
    payload
  }),
  confirmationFailed: (payload) => ({
    type: statusTypes.CONFIRMATION_FAIL,
    payload
  }),
  deleteBankRecord: (payload) => ({
    type: statusTypes.DELETE_BANK_RECORD_REQUEST,
    payload
  }),
  deleteRecordSuccess: (id) => ({
    type: statusTypes.DELETE_BANK_RECORD_SUCCESS,
    payload: { id }
  }),
  deleteRecordFailed: (id) => ({
    type: statusTypes.DELETE_BANK_RECORD_FAILED,
    payload: { id }
  }),
  generateAdenda: (payload) => ({
    type: statusTypes.GENERATE_ADENDA,
    payload
  }),
  generateAdendaSuccess: (payload) => ({
    type: statusTypes.GENERATE_ADENDA_SUCCESS,
    payload
  }),
  generateAdendaFailed: (payload) => ({
    type: statusTypes.GENERATE_ADENDA_FAIL,
    payload
  }),
  getClientsAccounts: (payload) => ({
    type: statusTypes.CLIENT_ACCOUNT_REQUEST,
    payload
  }),
  getClientsAccountsSuccess: (payload) => {
    return {
      type: statusTypes.CLIENT_ACCOUNT_SUCCESS,
      payload
    };
  },
  getClientsAccountsFailed: (payload) => ({
    type: statusTypes.CLIENT_ACCOUNT_FAILED,
    payload
  }),
};
