// @flow
import * as React from 'react';
import SelectReact from 'react-select';
import 'floating-label-react/styles.css';
import Wrapper from './Select.style';
import type { Props } from './Select.types';

const customStyles = {
  container: (provided) => ({
    ...provided,
    zIndex: 'unset'
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: 'transparent'
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 5000
  })
};

const Select = (props: Props) => {
  const { leftIcon, showAlert, ...rest } = props;

  return (
    <Wrapper>
      <div className="input-line">
        {leftIcon ? (
          <div className="input-img-container">
            <img className="input-img" alt="" src={leftIcon} />
          </div>
        ) : null}
        <SelectReact
          styles={customStyles}
          {...rest}
          maxMenuHeight={190}
          className={showAlert ? 'input-error' : 'sel'}
        />
      </div>
    </Wrapper>
  );
};
export { default as SelectButtonStyle } from './ButtonStyle';

export default Select;
