import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  height: 100% !important;
  width: 100%;
  .SelectButtonStyle {
    position: relative;
    height: 100% !important;
    width: 100%;
    &__options {
      z-index: 12;
      background-color: #fff !important;
      background-size: 100%;
      width: 100%;
      border-${(props) => props.mode}-left-radius: 6px;
      border-${(props) => props.mode}-right-radius: 6px;
      box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.1);
      position: absolute;
      display: none;
      ${(props) => (props.mode === 'top' ? 'bottom: 100%;' : 'top: 100%;')}
      &.active {
        display: block !important;
      }
      ul {
        width: 100%;
        li {
          min-height: 15px;
          padding: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 17px;
          border-${(props) => props.mode}-left-radius: 6px;
          border-${(props) => props.mode}-right-radius: 6px;

          cursor: pointer;
          transition-duration: 0.3s;
          transition-timing-function: linear;
          background-color: transparent;
          &:hover{
            transition-duration: 0.3s;
            transition-timing-function: linear;
            background-color: rgba(0,0,0,.05);
          
          }
        }
      }
    }
    button {
      z-index: 200;
    }
  }
`;
export { Wrapper };
