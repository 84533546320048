// @flow
import type { Saga } from 'redux-saga';
import { call, put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import status from './types';
import bankInvoicesAction from './actions';
import { getSelectedClientGroup } from '../selectors';
import { toast } from 'react-toastify';
import { Clients, BankRecords } from '../../api';

export function* getInvoices({ payload: { filters, pageSize = null, currentPage, clientGroupId = null, clientId = null } }: { payload: Object }): Saga {
  try {
    const page = currentPage || 0;

    const selectedClientGroupId = clientGroupId ?? (yield select(getSelectedClientGroup));
    
    const filtersPR = {
      clientId,
      clientGroupId: selectedClientGroupId,
      date_init: filters.invoice_date && filters.invoice_date[0].length > 0 ? filters.invoice_date[0] : undefined,
      date_final: filters.invoice_date && filters.invoice_date[1].length > 0 ? filters.invoice_date[1] : undefined,
      amount_init: filters.amount_range && filters.amount_range[0] > 0 ? filters.amount_range[0] : undefined,
      amount_final: filters.amount_range && filters.amount_range[1] > 0 ? filters.amount_range[1] : undefined
    };
    const request = { page, filters: filtersPR }
    if (pageSize) request.pageSize = pageSize
    const { data } = yield call(Clients.getClientInvoices, request);
    if (data.success) {
      const { invoices, paginationSize } = data;
      yield put(bankInvoicesAction.getBankInvoicesSuccess({ invoices, paginationSize }));
    } else {
      yield put(bankInvoicesAction.getBankInvoicesFails(data.message));
    }
  } catch (e) {
    yield put(bankInvoicesAction.getBankInvoicesFails(`Ocurrió un error al obtener las facturas ${e.toString()}`));
  }
}

export function* getPayments(): Saga {
  try {
    const { data } = yield call(Clients.getPayments, {});
    if (data.success) {
      const { payments } = data;
      yield put(bankInvoicesAction.getPaymentsSuccess({ payments }));
    } else {
      yield put(bankInvoicesAction.getPaymentsFails({}));
    }
  } catch (e) {
    yield put(bankInvoicesAction.getPaymentsFails({}));
  }
}

export function* getPreReceipt({ payload: { name, amountFrom, amountTo, dateFrom, dateTo, clientId, accountId, page, isPending } }: { payload: Object }): Saga {
  try {
    
    const filters = {
      ...(name !== undefined &&
        name.length > 0 && {
          name
        }),
      ...(amountFrom !== undefined &&
        amountFrom.length > 0 && {
          amountFrom
        }),
      ...(amountTo !== undefined &&
        amountTo.length > 0 && {
          amountTo
        }),
      ...(dateFrom !== undefined &&
        dateFrom.length > 0 && {
          dateFrom
        }),
      ...(dateTo !== undefined &&
        dateTo.length > 0 && {
          dateTo
        }),
      ...(clientId !== undefined &&
        clientId.length > 0 && {
          id: clientId
        }),
      ...(accountId !== undefined &&
        accountId.length > 0 && {
          accountId
        }),
      ...(page !== undefined && {
        page
      }),
      ...(isPending !== undefined &&
        isPending && {
          isPending
        })
    };

    const { data } = yield call(BankRecords.getPreReceipts, filters);

    if (data.success) {
      yield put(bankInvoicesAction.searchBankInvoicesSuccess({ bankInvoicesSearch: data.receipts, paginationSize: data.paginationSize }));
    } else {
      yield put(bankInvoicesAction.getPreReceiptFails({ message: status.AN_ERROR_HAS_OCURRED }));
    }
  } catch (error) {
    yield put(bankInvoicesAction.getPreReceiptFails({ message: status.AN_ERROR_HAS_OCURRED }));
  }
}

export function* sendReceiptsFiltering({ payload: { name, amountFrom, amountTo, dateFrom, dateTo, clientId, accountId, page, isPending } }: { payload: Object }): Saga {
  try {
    
    const filters = {
      ...(name !== undefined &&
        name.length > 0 && {
          name
        }),
      ...(amountFrom !== undefined &&
        amountFrom.length > 0 && {
          amountFrom
        }),
      ...(amountTo !== undefined &&
        amountTo.length > 0 && {
          amountTo
        }),
      ...(dateFrom !== undefined &&
        dateFrom.length > 0 && {
          dateFrom
        }),
      ...(dateTo !== undefined &&
        dateTo.length > 0 && {
          dateTo
        }),
      ...(clientId !== undefined &&
        clientId.length > 0 && {
          id: clientId
        }),
      ...(accountId !== undefined &&
        accountId.length > 0 && {
          accountId
        }),
      ...(page !== undefined && {
        page
      }),
      ...(isPending !== undefined &&
        isPending && {
          isPending
        })
    };

    const { data } = yield call(BankRecords.sendReceiptsFiltering, filters);

    if (data.success) {
      yield call(toast.success, data.message , { position: toast.POSITION.BOTTOM_LEFT });
    } else {
      yield call(toast.error, data.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }

  } catch (error) {
    yield call(toast.error, 'Ha ocurrido un error', {
      position: toast.POSITION.BOTTOM_LEFT
    });
  }
}

export function* getBankInvoices({ payload: { name, amountFrom, amountTo, dateFrom, dateTo, clientId, accountId, page, isPending, bank } }: { payload: Object }): Saga {
  try {

    const filters = {
      ...(name !== undefined &&
        name.length > 0 && {
          name
        }),
      ...(amountFrom !== undefined &&
        amountFrom.length > 0 && {
          amountFrom
        }),
      ...(amountTo !== undefined &&
        amountTo.length > 0 && {
          amountTo
        }),
      ...(dateFrom !== undefined &&
        dateFrom.length > 0 && {
          dateFrom
        }),
      ...(dateTo !== undefined &&
        dateTo.length > 0 && {
          dateTo
        }),
      ...(clientId !== undefined &&
        clientId.length > 0 && {
          id: clientId
        }),
      ...(accountId !== undefined &&
        accountId.length > 0 && {
          accountId
        }),
      ...(page !== undefined && {
        page
      }),
      ...(isPending !== undefined &&
        isPending && {
          isPending
        }),
      ...(bank !== undefined &&
        bank.length > 0 && {
          bank
        })
    };

    const { data } = yield call(BankRecords.getBankInvoices, filters);

    if (data.success) {
      yield put(bankInvoicesAction.searchBankInvoicesSuccess({ bankInvoicesSearch: data.receipts, paginationSize: data.paginationSize }));
    } else {
      yield put(bankInvoicesAction.getBankInvoicesFail({ message: status.AN_ERROR_HAS_OCURRED }));
    }
  } catch (error) {
    yield put(bankInvoicesAction.getBankInvoicesFail({ message: status.AN_ERROR_HAS_OCURRED }));
  }
}

export function* postAnnulation({ payload: { internalReceiptId, adenda, disableBalance } }: { payload: Object }): Saga {
  try {
    const { data } = yield call(BankRecords.cancelInternalReceipt, {params: { internalReceiptId, adenda }});


    if (data.success) {
      yield put(bankInvoicesAction.postAnnulationSuccess({ ucfeInvoiceNro: data.ucfeInvoiceNro, 
        receipt: data.receipt, message: status.SUCCESS_ANNULATION_RECEIPT }));
        disableBalance();
    } else {
      yield put(bankInvoicesAction.getBankInvoicesFail({ message: data.message!==''? data.message : status.AN_ERROR_HAS_OCURRED }));
    }

  } catch (error) {
    yield put(bankInvoicesAction.getBankInvoicesFail({ message: status.AN_ERROR_HAS_OCURRED }));
  }
}

export function* postEmit({ payload: { internalReceiptId, idBankRecord ,adenda } }: { payload: Object }): Saga {
  try {
    const { data } = yield call(BankRecords.emitInternalReceipt, {params: { internalReceiptId, idBankRecord, adenda }});

    if (data.success) {
      yield put(bankInvoicesAction.postEmitSuccess({ ucfeInvoiceNro: data.ucfeInvoiceNro, 
        receipt: data.receipt, message: status.BANK_INVOICES_EMIT_SUCCESS }));
    } else {
      yield put(bankInvoicesAction.postEmitFail({ message: data.message!==''? data.message : status.AN_ERROR_HAS_OCURRED }));
    }

  } catch (error) {
    yield put(bankInvoicesAction.postEmitFail({ message: status.AN_ERROR_HAS_OCURRED }));
  }
}

export function* postModifyBank({ payload: { internalReceiptId, idBankRecord ,adenda } }: { payload: Object }): Saga {
  try {
    const { data } = yield call(BankRecords.modifyBankInternalReceipt, {params: { internalReceiptId, idBankRecord, adenda }});

    if (data.success) {
      yield call(toast.success, data.message , { position: toast.POSITION.BOTTOM_LEFT });
    } else {
      yield call(toast.error, data.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }

  } catch (error) {
    yield put(bankInvoicesAction.postModifyBankFail({ message: status.AN_ERROR_HAS_OCURRED }));
  }
}

export function* postRemoveAmount({ payload: { internalReceiptId } }: { payload: Object }): Saga {
  try {
    const { data } = yield call(BankRecords.removeAmountInternalReceipt, {params: { internalReceiptId }});

    if (data.success) {
      yield put(bankInvoicesAction.postRemoveAmountSuccess({ 
        receipt: data.receipt, message: status.BANK_INVOICES_REMOVE_AMOUNT_SUCCESS }));
    } else {
      yield put(bankInvoicesAction.postRemoveAmountFail({ message: data.message!==''? data.message : status.BANK_INVOICES_REMOVE_AMOUNT_FAILED }));
    }

  } catch (error) {
    yield put(bankInvoicesAction.postRemoveAmountFail({ message: status.BANK_INVOICES_REMOVE_AMOUNT_FAILED }));
  }
}

export function* postShareInvoicePDF({ payload: { invoiceId,email } }: { payload: Object }): Saga {
  try {
    const { data } = yield call(BankRecords.shareInvoice, {params: { invoiceId,email }});

    if (data.success) {
      yield put(bankInvoicesAction.postShareInvPDFSuccess({ shared: data.shared, message: data.message }));
    } else {
      yield put(bankInvoicesAction.postShareInvPDFFail({ message: data.message!==''? data.message : status.AN_ERROR_HAS_OCURRED }));
    }

  } catch (error) {
    yield put(bankInvoicesAction.postShareInvPDFFail({ message: status.AN_ERROR_HAS_OCURRED }));
  }
}

export function* checkAndEmit() {
  try {
    const { data } = yield call(BankRecords.checkAndEmit);

    if (data.success) {
      yield call(toast.success, data.message , { position: toast.POSITION.BOTTOM_LEFT });
    } else {
      yield call(toast.error, data.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }

  } catch (error) {
    yield call(toast.error, 'Ha ocurrido un error', {
      position: toast.POSITION.BOTTOM_LEFT
    });
  }
}

export function* postSharePDFReceipt({ payload: { internalReceiptId,email } }: { payload: Object }): Saga {
  try {
    const { data } = yield call(BankRecords.shareInternalReceipt, {params: { internalReceiptId,email }});

    if (data.success) {
      yield put(bankInvoicesAction.postSharePDFSuccess({ shared: data.shared, message: data.message }));
    } else {
      yield put(bankInvoicesAction.postSharePDFFail({ message: data.message!==''? data.message : status.AN_ERROR_HAS_OCURRED }));
    }

  } catch (error) {
    yield put(bankInvoicesAction.postSharePDFFail({ message: status.AN_ERROR_HAS_OCURRED }));
  }
}

export function* postDecline({ payload: { internalReceiptId,reason } }: { payload: Object }): Saga {
  try {
    const { data } = yield call(BankRecords.declineSellerReceipt, {params: { internalReceiptId,reason }});

    if (data.success) {
      yield put(bankInvoicesAction.postDeclineSuccess({ receiptId: data.receiptId, message: status.BANK_INVOICES_DECLINE_SUCCESS }));
    } else {
      yield put(bankInvoicesAction.postDeclineFail({ message: data.message!==''? data.message : status.AN_ERROR_HAS_OCURRED }));
    }

  } catch (error) {
    yield put(bankInvoicesAction.postDeclineFail({ message: status.AN_ERROR_HAS_OCURRED }));
  }
}
export function* postPreReciboEmitir({ payload: { selectedInvoices } }: { payload: Object }): Saga {
  try {
    const { data } = yield call(BankRecords.emitBulkInternalReceipt, {params: { selectedInvoices }});

    if (data.success) {
      yield call(toast.success, data.message , { position: toast.POSITION.BOTTOM_LEFT });
    } else {
      yield put(bankInvoicesAction.getBankInvoicesFail({ message: data.message!==''? data.message : status.AN_ERROR_HAS_OCURRED }));
    }

  } catch (error) {
    yield put(bankInvoicesAction.getBankInvoicesFail({ message: status.AN_ERROR_HAS_OCURRED }));
  }
}
export function* postPreReciboReject({ payload: { selectedInvoices } }: { payload: Object }): Saga {
  try {
    const { data } = yield call(BankRecords.rejectBulkInternalReceipt, {params: { selectedInvoices }});

    if (data.success) {
      yield call(toast.success, data.message , { position: toast.POSITION.BOTTOM_LEFT });
    } else {
      yield put(bankInvoicesAction.getBankInvoicesFail({ message: data.message!==''? data.message : status.AN_ERROR_HAS_OCURRED }));
    }

  } catch (error) {
    yield put(bankInvoicesAction.getBankInvoicesFail({ message: status.AN_ERROR_HAS_OCURRED }));
  }
}
export default function* bankInvoices(): Saga {
  yield takeEvery(status.GET_BANK_INVOICES_REQUEST, getInvoices);
  yield takeEvery(status.GET_PRE_INVOICES_REQUEST, getPreReceipt);
  yield takeEvery(status.GET_BANK_INVOICES_REQUEST, getPayments);
  yield takeLatest(status.GET_PAYMENT_METHODS_REQUEST, getPayments);
  yield takeEvery(status.BANK_INVOICES_REQUEST, getBankInvoices);
  yield takeLatest(status.BANK_INVOICES_ANNULATION, postAnnulation);
  yield takeLatest(status.BANK_INVOICES_EMIT, postEmit);
  yield takeLatest(status.BANK_INVOICES_MOD_BANK, postModifyBank);
  yield takeLatest(status.BANK_INVOICES_SHARE_PDF, postSharePDFReceipt);
  yield takeLatest(status.INVOICE_SHARE_PDF, postShareInvoicePDF);
  yield takeLatest(status.CHECK_AND_EMIT,checkAndEmit);
  yield takeLatest(status.SEND_RECEIPTS_FILTERING,sendReceiptsFiltering);
  yield takeLatest(status.BANK_INVOICES_REMOVE_AMOUNT, postRemoveAmount);
  yield takeLatest(status.BANK_INVOICES_DECLINE, postDecline);
  yield takeLatest(status.BANK_PRE_RECIBO_EMITIR, postPreReciboEmitir)
  yield takeLatest(status.BANK_PRE_RECIBO_REJECT, postPreReciboReject)
}
