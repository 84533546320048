// @flow
import type { StatelessFunctionalComponent } from 'react';
import styled from 'styled-components';

const ButtonWrapper: StatelessFunctionalComponent<*> = styled.button`
  font-family: var(--font-family-alt);
  font-weight: 500 !important;
  padding: 0.35em .875em !important;
  min-width: 218px;
  line-height: 1;
  cursor: pointer;
  font-size: 2.2em !important;
  border: 2px solid transparent;
  color: var(--white-color);
  background-color: var(--active-button);
  transition: background-color 0.25s ease;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  > span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  > span > * + * {
    margin-left: .5em !important;
  }

  &:hover {
    background-color: var(--red-light-color);
  }

  &:focus {
    box-shadow: 0 0 8px 0 rgba(3, 88, 189, 0.7);
  }

  &:disabled {
    cursor: default;
    background-color: var(--inactive-button);

    svg,
    img {
      filter: grayscale(1);
      opacity: .25;
    }
  }

  ${(props) =>
    props.hidden &&
    `
    display: none;
  `}

  ${(props) =>
    props.secondary &&
    `
    background-color: transparent;
    border-color: var(--active-button);
    color: var(--active-button);
    transition: border-color 0.25s ease, color 0.25s ease;

    &:hover {
      background-color: transparent;
      border-color: var(--red-light-color);
      color: var(--red-light-color);
    }

    &:disabled {
      background-color: transparent;
      border-color: var(--inactive-button);
      color: var(--inactive-button);
    }
  `}

  ${(props) =>
    props.terciary &&
    `
    background-color: transparent;
    border-color: var(--yellow-color);
    color: var(--yellow-color);
    transition: border-color 0.25s ease, color 0.25s ease;

    &:hover {
      background-color: transparent;
      border-color: hsl(44, 100%, 70%);
      color: hsl(44, 100%, 70%);
    }

    &:disabled {
      background-color: transparent;
      border-color: var(--inactive-button);
      color: var(--inactive-button);
    }

    &:focus {
      box-shadow: 0 0 8px 0 hsl(44, 100%, 85%);
    }
  `}

  ${(props) =>
    props.link &&
    `
    font-size: inherit!important;
    text-transform: none;
    padding: 0 !important;
    min-width: 0;
    text-align: left;
    background-color: transparent;
    color: var(--active-button);
    border: 0;
    transition: color 0.25s ease;

    &:hover {
      background-color: transparent;
      color: var(--red-light-color);
    }

    &:focus {
      box-shadow: none;
    }

    &:disabled {
      background-color: transparent;
      color: var(--inactive-button);
    }
  `}

  ${(props) =>
    props.plain &&
    `
    padding: 0 !important;
    min-width: 0;
    text-align: left;
    background-color: transparent;
    color: var(--active-button);
    border: 0;
    transition: color 0.25s ease;

    &:hover {
      background-color: transparent;
      color: var(--red-light-color);
    }

    &:focus {
      box-shadow: none;
    }

    &:disabled {
      background-color: transparent;
      color: var(--inactive-button);
    }
  `}

  ${(props) =>
    props.block &&
    `
    display: flex;
    width: 100%;
  `}

  ${(props) =>
    props.block &&
    props.plain &&
    `
    text-align: center;
    transition: background 0.25s ease;

    &:hover {
      background-color: #f3f3f3;
    }
  `}

  ${(props) =>
    props.isloading &&
    `
    position: relative;

    > div {
      margin-left: 0 !important;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }

    > span {
      visibility: hidden;
    }
  `}
`;

export default ButtonWrapper;
