import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ToastContainer, toast, Slide } from 'react-toastify';
import storeInfo, { history } from './ducks/store';
import Routes from './router';
import Wrapper from './index.style';
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css/animate.min.css';
import ScrollToTop from './components/ScrollToTop';
import './autoload';
import './utilities.css';
// eslint-disable-next-line no-unused-vars
import GlobalStyle from './components/ui/GlobalStyle';

ReactDOM.render(
  <Provider store={storeInfo.store}>
    <ConnectedRouter history={history}>
      <Wrapper>
        <PersistGate loading={null} persistor={storeInfo.persistor}>
          <ScrollToTop>
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} language="es">
              <Routes />
              <ToastContainer
                position={toast.POSITION.BOTTOM_LEFT}
                autoClose={15000}
                transition={Slide}
                hideProgressBar
                closeOnClick
              />
            </GoogleReCaptchaProvider>
          </ScrollToTop>
        </PersistGate>
      </Wrapper>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('rootWurth')
);
