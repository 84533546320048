import moment from 'moment';
import { TIPO_DOC, CFE_TYPE } from './constants';

export * from './constants';
export * from './excel';

export const cfeTypeString = (cfeType) => {
  switch (cfeType) {
    case CFE_TYPE.eFactura:
      return 'eFactura';
    case CFE_TYPE.eTicket:
      return 'eTicket';
    case CFE_TYPE.notaEFactura:
      return 'NC eFactura';
    case CFE_TYPE.notaETicket:
      return 'NC eTicket';
    default:
      return cfeType.toString();
  }
};

export const isNotaCredito = (cfeType) => {
  return cfeType === CFE_TYPE.notaEFactura || cfeType === CFE_TYPE.notaETicket;
};
export const formatAmount = (amount, currency = 'UYU') => {
  let auxFormat;
  if (amount !== 0) {
    auxFormat = `${new Intl.NumberFormat('de-DE').format(parseFloat(amount).toFixed(2))}${
      parseFloat(amount) % 1 === 0 ? ',00' : ''
    }`;
  } else {
    auxFormat = '0,00';
  }

  return `${currency} ${auxFormat}`;
};

export const formatInternalReceiptStatus = (statusId) => {
  switch (statusId) {
    case 1:
      return 'Pendiente';
    case 2:
      return 'Aprobado';
    case 3:
      return 'Rechazado';
    default:
      return statusId.toString();
  }
};

export const containsDoc = (str, doc) => str.includes(doc);
export const containsLowercase = (str) => /[a-z]/.test(str);
export const containsUppercase = (str) => /[A-Z]/.test(str);
export const containsNumber = (str) => /\d/.test(str);
export const containsLetter = (str) => /[A-Za-z]/.test(str);
export const containsNumberLetter = (str) => containsNumber(str) && containsLetter(str);
export const validLicensePlate = (license) => /^[A-Z]{3} ?\d{4}$/.test(license);
export const containsWord = (str, w) => str.includes(w);

// finders
export const getKeyFromValue = (obj, val) => {
  const keys = Object.keys(obj);
  let key;
  let value;

  keys.forEach((k) => {
    if (obj[k] === val) {
      key = k;
      value = val;
    }
  });

  return key;
};
/* PASSWORD */
export const encodePassword = (password) => Buffer.from(password).toString('base64');

export const validatePassword = (psw, ci = '', rut = '', nroAsegurado = '') =>
  psw.length >= 8 &&
  containsUppercase(psw) &&
  containsLowercase(psw) &&
  containsNumberLetter(psw) &&
  (!ci || !containsDoc(psw, ci)) &&
  (!rut || !containsDoc(psw, rut)) &&
  (!nroAsegurado || !containsDoc(psw, nroAsegurado));

/* EMAIL */
export const validateEmail = (email) => {
  const re = /^(([^<>()\]\\.,;:\s@']+(\.[^<>()\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

/* VALIDACION DE CI Y RUT */
const ciValidationDigit = (ciParam: string) => {
  let ci = ciParam;
  ci = ci.substr(0, ci.length - 1);

  let suma = 0;
  let i = 0;
  const factores = [2, 9, 8, 7, 6, 3, 4];
  if (ci.length <= 6) {
    for (i = ci.length; i < 7; i++) {
      ci = '0' + ci;
    }
  }
  for (i = 0; i < 7; i++) {
    suma += (factores[i] * parseInt(ci[i], 10)) % 10;
  }
  if (suma % 10 === 0) {
    return 0;
  }
  return 10 - (suma % 10) + '';
};

const rutValidationDigit = (rutParam: string) => {
  let rut = rutParam;
  if (rut.length === 11) {
    rut = '0' + rut;
  }
  let dig;
  let suma = 0;
  let modulo = 0;
  const factores = [4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

  for (let i = rut.length - 2; i >= 0; i--) {
    suma += parseInt(rut[i], 10) * factores[i];
  }
  modulo = suma % 11;
  dig = 11 - modulo;
  if (dig === 11) {
    dig = 0;
  }
  if (dig === 10) {
    dig = 1;
  }

  return dig + '';
};

export const validateCI = (val: string): boolean => {
  // Validación de CI

  // Obtener solo los número sin puntos ni guiones
  const doc = val.replace(/\D/g, '');
  // Obtengo el digito verificador del doc que me pasan
  const dig = doc[doc.length - 1];
  // Valido el largo del documento y comparo el digito verificador con el calculado
  const ciValid = doc.length <= 8 && dig === ciValidationDigit(doc);
  let containsOtherThanZero = false;
  for (let i = 0; i < doc.length; i++) {
    if (doc[i] !== '0') {
      containsOtherThanZero = true;
      break;
    }
  }
  if (!ciValid || !containsOtherThanZero) {
    return false; // "El formato de la CI no es válido"
  }
  return true;
};

export const validateRUT = (val) => {
  // Validación de RUT

  // Obtener solo los número sin puntos ni guiones
  const doc = val.replace(/\D/g, '');
  // Idem que en ci, solo cambia el algoritmo al que llamo y el largo
  const dig = doc[doc.length - 1];
  const rutValid = doc.length <= 12 && dig === rutValidationDigit(doc);
  let containsOtherThanZero = false;
  for (let i = 0; i < doc.length; i++) {
    if (doc[i] !== '0') {
      containsOtherThanZero = true;
      break;
    }
  }
  if (!rutValid || !containsOtherThanZero) {
    return false; // "El formato del RUT no es válido"
  }
  return true;
};

/* STRINGS */
export const capitalize = (lower: string) =>
  (lower ? lower.toLowerCase() : lower).replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());

/* DATES */
// dateString format dd/mm/YYYY
export const stringToDate = (dateString) => {
  const date = dateString.split('/');
  if (date.length === 3) return new Date(date[2], date[1] - 1, date[0]);

  return new Date();
};

export const dateToString = (date) => `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

export const getDiffFromTodayInDays = (date): number => {
  const date1 = new Date();
  const date2 = new Date(date);
  const timeDiff = date2.getTime() - date1.getTime();
  const daysDiff = timeDiff / (1000 * 3600 * 24);

  return Math.abs(Math.ceil(daysDiff));
};

export const mapSelectList = (list, value = 'clave', label = 'valor') =>
  list
    ? list.map((obj) => {
        const rObj = {};
        rObj.value = obj[value];
        rObj.label = obj[label];
        return rObj;
      })
    : null;

/* OTHERS */
export const payments = (rate) => {
  const paymentsAux = [];

  rate.forEach((obj) => {
    if (obj.FeeAmount && obj.FeeAmount !== 0) {
      paymentsAux.unshift({ value: obj.FeeAmount, label: obj.FeeAmount + ' cuotas' });
    }
  });

  return paymentsAux;
};

/* Ciudades de Canelones deshabilitadas */
export const disabledCities = [
  30,
  101,
  151,
  813,
  816,
  817,
  818,
  819,
  820,
  821,
  822,
  823,
  824,
  825,
  826,
  827,
  828,
  829,
  830,
  831,
  832,
  833,
  834,
  835,
  840,
  841
];

/* Códigos para Ciudades */
export const cityCodes = [
  { label: 'MONTEVIDEO', code: 1 },
  { label: 'ARTIGAS', code: 11 },
  { label: 'CERRO LARGO', code: 131 },
  { label: 'COLONIA', code: 153 },
  { label: 'DURAZNO', code: 211 },
  { label: 'FLORES', code: 241 },
  { label: 'FLORIDA', code: 271 },
  { label: 'LAVALLEJA', code: 301 },
  { label: 'MALDONADO', code: 331 },
  { label: 'PAYSANDU', code: 371 },
  { label: 'RIO NEGRO', code: 421 },
  { label: 'RIVERA', code: 461 },
  { label: 'ROCHA', code: 481 },
  { label: 'SALTO', code: 521 },
  { label: 'SAN JOSE', code: 551 },
  { label: 'SORIANO', code: 581 },
  { label: 'TACUAREMBO', code: 621 },
  { label: 'TREINTA Y TRES', code: 65 }
];

/* Códigos para Deptos */
export const deptoCodes = [
  { label: 'MONTEVIDEO', code: 1 },
  { label: 'ARTIGAS', code: 2 },
  { label: 'CANELONES', code: 3 },
  { label: 'CERRO LARGO', code: 4 },
  { label: 'COLONIA', code: 5 },
  { label: 'DURAZNO', code: 6 },
  { label: 'FLORES', code: 7 },
  { label: 'FLORIDA', code: 8 },
  { label: 'LAVALLEJA', code: 9 },
  { label: 'MALDONADO', code: 10 },
  { label: 'PAYSANDU', code: 11 },
  { label: 'RIO NEGRO', code: 12 },
  { label: 'RIVERA', code: 13 },
  { label: 'ROCHA', code: 14 },
  { label: 'SALTO', code: 15 },
  { label: 'SAN JOSE', code: 16 },
  { label: 'SORIANO', code: 17 },
  { label: 'TACUAREMBO', code: 18 },
  { label: 'TREINTA Y TRES', code: 19 }
];

/* Códigos para Genero */
export const genreCodes = [
  { label: 'Femenino', code: 'F' },
  { label: 'Masculino', code: 'M' }
];

/* Códigos para condiciones de pago */
export const paymentConditions = [
  { label: '60 días', code: 0 },
  { label: '90 días', code: 1 },
  { label: '30 días', code: 2 },
  { label: '120 días', code: 3 },
  { label: '150 días', code: 4 },
  { label: '180 días', code: 5 },
  { label: '30 y 60 días', code: 20 },
  { label: '30, 60 y 90 días', code: 10 },
  { label: 'Contado', code: 101 },
];

export const associatedDocText = (docType) => {
  if (docType === TIPO_DOC.RUT) {
    return 'Documento asociado - RUT';
  }
  if (docType === TIPO_DOC.CI) {
    return 'Documento asociado - CI';
  }
  return 'Documento asociado - Documento extranjero';
};

export const dateAlert = (date) => {
  const today = new Date();
  const dateVto = stringToDate(date);
  const diffTime = Math.abs(today - dateVto);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  if ((diffDays <= 3 && dateVto >= today) || diffDays === 1) {
    return 'Vence en ' + diffDays + ' días';
  }
  return date;
};

/**
 * Recibe codCoin o coinDescription y retorna el simbolo UYU o USD
 * @param {number | string} value
 */
export const getCurrencySymbol = (value) => {
  let symbol;

  switch (value) {
    case '$':
    case 0:
    case 'PESOS URUGUAYOS':
      symbol = 'UYU';
      break;
    case 1:
    case 'DOLARES U.S.A.':
      symbol = 'USD';
      break;
    default:
      symbol = 'UYU';
      break;
  }

  return symbol;
};

export const driverRequestBalanceText = (availableServices) => {
  if (availableServices === 0) return 'No hay servicios disponibles';
  if (availableServices === 1) return availableServices + ' servicio disponible';
  return availableServices + ' servicios disponibles';
};

// Posiciones en donde se muestran las preguntas
export const questionsPositions = [0, 4, 8];

export const isValidFile = (type, size) => {
  const types = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];

  /* 10MB maximo */
  if (size > 10485760 / 2) {
    return false;
  }

  if (!types.includes(type)) {
    return false;
  }

  return true;
};

export const sortList = (type, key, order, list) => {
  const newList = [...list];
  switch (type) {
    case 'letter':
      newList.sort((a, b) => {
        if (a[key] > b[key]) {
          return 1 * order;
        }
        if (b[key] > a[key]) {
          return -1 * order;
        }
        return 0;
      });
      break;
    case 'number':
      newList.sort((a, b) => ((a[key] || 0) - (b[key] || 0)) * order);
      break;
    case 'date':
      newList.sort((a, b) => (moment(a[key]) - moment(b[key])) * order);
      break;
    default:
      newList.sort((a, b) => (a[key] - b[key]) * order);
      break;
  }
  return newList;
};

export const findPosWithAttrName = (array, key1, key2) => {
  for (let i = 0; i < array.length; i += 1) {
    if (array[i][key1] === key2) {
      return i;
    }
  }
  return -1;
};

const paymentsCodeMapper = {
  0: 60,
  1: 90,
  2: 30,
  3: 120,
  4: 150,
  5: 180
};

export const getPaymentDays = (array, key) => {
  if (array !== undefined) {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i].code === key) {
        switch (key) {
          case 101: {
            return '' + array[i].description;
          }
          case 10: {
            return 90 + ' días';
          }
          case 20: {
            return 60 + ' días';
          }
          default: {
            const days = paymentsCodeMapper[array[i].code];
            return days + ' días';
          }
        }
      }
    }
  }
  return '';
};

export const calculateAmountInvoices = (invoiceFees) => {
  // Si es nota de crédito, el saldo es negativo
  var sum = 0;
  invoiceFees.forEach(fee => {
    var feeNumAmount = parseFloat(fee.apply_amount)
    if(isNotaCredito(fee.cfeType))
      sum = parseFloat(sum) - feeNumAmount 
    else 
      sum = parseFloat(sum) + feeNumAmount
  });
  
  return sum;
  
  // return invoiceFees.map((fee) => {
  //     return parseFloat(fee.apply_amount).toFixed(2) * 
  //     (isNotaCredito(fee.cfeType) ? -1 : 1)
  //   }).reduce((a, c) => a + c, 0);
};