import React, { useState, useRef } from 'react';
import _ from 'lodash';
import Button from '../../Button';
import { Wrapper } from './styles';

const Select = ({
  onChange,
  disabled,
  defaultOption,
  handleReset,
  CustomButton,
  options,
  children,
  mode,
  closeOnSelect,
  ...rest
}) => {
  const animationFadeIn = mode === 'top' ? 'fadeInUp' : 'fadeInDown';
  const animationFadeOut = mode === 'top' ? 'fadeOutDown' : 'fadeOutUp';
  const optionsRef = useRef(null);
  const [transaction, setTransaction] = useState(false);
  const [optionSelected, selectOption] = useState(defaultOption);
  const [open, setState] = useState(false);
  const [animation, setAnimation] = useState(animationFadeIn);
  const [id] = useState(_.uniqueId('select__'));
  let timeout;

  function reset() {
    selectOption(null);
  }
  handleReset(reset);

  React.useEffect(() => {
    if (defaultOption) selectOption(defaultOption);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [defaultOption]);
  const toggle = (value) => {
    if (!transaction) {
      setTransaction(true);
      setAnimation(value ? animationFadeIn : animationFadeOut);
      if (value) {
        setState(value);
        setTransaction(false);
      } else {
        timeout = setTimeout(() => {
          setState(value);
          setTransaction(false);
        }, 200);
      }
    }
  };

  function handleFocus(e) {
    toggle(true);
  }

  function handleBlur(e) {
    e.persist();
    e.preventDefault();
    const { relatedTarget } = e;
    if (!relatedTarget?.classList?.contains(id)) {
      toggle(false);
    }
  }

  function displayBoxE(e) {
    var obj = document.getElementsByClassName('SelectButtonStyle__options')[0]
    var ua = window.navigator.userAgent;
    var isSafari = !!ua.match(/Safari/i);
    console.log('Is safari 4' + isSafari);
    if(e.currentTarget.children[0].classList.contains('fadeInUp') || e.currentTarget.children[0].classList.contains('fadeOutUp'))
      handleFocus(e)
    else
      handleBlur(e)
  } 

  const handleOptionClick = (option) => {
    optionsRef.current.blur();
    selectOption(option.text);
    onChange(option);
    if (closeOnSelect) toggle(false);
  };
  return (
    <Wrapper mode={mode}>
      <div onClick={displayBoxE} ref={optionsRef} className="SelectButtonStyle">
        <div className={`SelectButtonStyle__options ${animation} animated faster ${open && 'active'}`}>
          <ul className={id} tabIndex={-1}>
            {options.map((option, index) => (
              <li onClick={() => handleOptionClick(option)} key={index}>
                {option.text}
              </li>
            ))}
          </ul>
        </div>
        <CustomButton {...rest}>{optionSelected === null ? children : optionSelected}</CustomButton>
      </div>
    </Wrapper>
  );
};
Select.defaultProps = {
  mode: 'top',
  CustomButton: (props) => <Button {...props}>{props.children}</Button>,
  handleReset: () => {},
  onChange: () => {},
  defaultOption: null,
  disabled: false,
  closeOnSelect: true
};
export default Select;
