import statusTypes from './types';

export default {
  setSellerReceiptValue: (payload) => ({
    type: statusTypes.SET_SELLER_RECEIPT_CLIENT,
    payload
  }),
  setSelectedClient: (payload) => ({
    type: statusTypes.SET_SELECTED_CLIENT,
    payload
  }),
  resetConfirmationResult: (payload) => ({
    type: statusTypes.RESET_CONFIRMATION_RESULT,
    payload
  }),
  addState: (payload) => ({
    type: statusTypes.ADD_STATE,
    payload
  }),
  clientVerification: (payload) => ({
    type: statusTypes.GET_CLIENT_VERIFICATION,
    payload
  }),
  addPaymentMethod: (key, paymentMethod) => ({
    type: statusTypes.ADD_PAYMENT_METHOD,
    payload: { key, paymentMethod }
  }),
  createSellerReceipt: (payload) => ({
    type: statusTypes.CREATE_SELLER_RECEIPT,
    payload
  }),
  clearClientVerification: () => ({
    type: statusTypes.CLEAR_CLIENT_VERIFICATION
  })
};
