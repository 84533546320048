// @flow
import * as React from 'react';
import InfoWrapper from './Info.style';
import type { InfoProps } from './Info.types';

const Info = (props: InfoProps) => {
  const {
    icon,
    title,
    value,
    ...rest
  } = props;

  return (
    <InfoWrapper {...rest}>
      <img className="Info-icon" src={icon} alt="" />

      <div>
        <span className="Info-label">{title}</span>
        <div className="Info-value">{value}</div>
      </div>
    </InfoWrapper>
  );
};

Info.defaultProps = {
  title: 'Título',
  value: 'Valor',
  icon: 'https://placehold.it/30x30'
};

export default Info;
