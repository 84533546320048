import types from './types';

export default {
  getBankRecordsWithPositiveBalance: (payload) => ({
    type: types.GET_BANK_RECORDS_WITH_POSITIVE_BALANCE_REQUEST,
    payload
  }),
  getBankRecordsWithPositiveBalanceSuccess: (payload) => ({
    type: types.GET_BANK_RECORDS_WITH_POSITIVE_BALANCE_SUCCESS,
    payload
  }),
  getBankRecordsWithPositiveBalanceFailed: (payload) => ({
    type: types.GET_BANK_RECORDS_WITH_POSITIVE_BALANCE_FAILED
  }),

  setInternalReceiptWithPositiveBalance: (payload) => ({
    type: types.SET_INTERNAL_RECEIPT_WITH_POSITIVE_BALANCE,
    payload
  }),

  // FORM
  setForm: (payload) => ({
    type: types.SET_ASSIGN_BANK_RECORD_POSITIVE_BALANCE_SET_FORM,
    payload
  }),
  sendConfirmation: () => ({
    type: types.ASSIGN_BANK_RECORD_POSITIVE_BALANCE_SEND_CONFIRMATION_REQUEST
  }),
  sendConfirmationSuccess: () => ({
    type: types.ASSIGN_BANK_RECORD_POSITIVE_BALANCE_SEND_CONFIRMATION_SUCCESS
  }),
  sendConfirmationFailed: () => ({
    type: types.ASSIGN_BANK_RECORD_POSITIVE_BALANCE_SEND_CONFIRMATION_FAILED
  }),

  resetState: () => ({
    type: types.ASSIGN_BANK_RECORD_POSITIVE_BALANCE_RESET_STATE
  })
};
