import types from './types';

export const getAccountabilities = (payload) => ({
  type: types.GET_ACCOUNTABILITIES_REQUEST,
  payload
});

export const getAccountabilitiesSuccess = (payload) => ({
  type: types.GET_ACCOUNTABILITIES_SUCCESS,
  payload
});

export const getAccountabilitiesFailed = () => ({
  type: types.GET_ACCOUNTABILITIES_FAILED
});


export const getAccountabilityDetail = (payload, cb)=>({
  type: types.GET_ACCOUNTABILITY_DETAIL_REQUEST,
  payload,
  cb
})
export const getAccountabilityDetailSuccess = (payload)=>({
  type: types.GET_ACCOUNTABILITY_DETAIL_SUCCESS,
  payload
})

export const updateAccountabilityDetail = (payload, cb) => ({
  type: types.UPDATE_ACCOUNTABILITY_DETAIL_REQUEST,
  payload,
  cb
})