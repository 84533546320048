import axios from '../axiosConfig';

export default {

  getAll: (params) => axios.get('/accountabilities/', { params }).then((res) => res.data),

  getDeposits: () =>  axios.get('/deposits').then((res) => res.data),

  deleteDeposit: (request) =>  axios.post('/deposits/deleteDeposit', request).then((res) => res.data), // {deposit_id}
  
  assignDeposit: (params) =>  axios.post('/deposits/assignDeposit', { params }).then((res) => res.data), // [accountability_id, *]

  createDeposit: (request) =>  axios.post('/deposits/createDeposits',request).then((res) => res.data), // MISMO OBJETO QUE EL OTRO

  getPostedAccountabilities: () =>  axios.get('/accountabilities/getPosted').then((res) => res.data), 

  getAccountability: (accountabilityId)=> axios.get(`/accountabilities/${accountabilityId}`).then((res) => res.data),
  
  getAccountabilityReceitps: (request)=> axios.get(`/internal-receipts/getAccountabilityReceipts`, { data: request }).then((res) => res.data),

  sendAccountability: (request)=>axios.post('/accountabilities/sendAccountability', request).then((res) => res.data),

  updateAccountabilityDetail: (request) => axios.post('/accountabilities/updateAccountabilityDetail', request).then((res) => res.data)
};
