import { configurationsTypes } from './types';

export default {
  setConfigurations: payload => ({
    type: configurationsTypes.SET_CONFIGURATIONS,
    payload
  }),

  getConfigurations: () => ({
    type: configurationsTypes.GET_CONFIGURATIONS_REQUEST,
    payload: null
  }),

  updateConfiguration : (payload, cb)=> ({
    type: configurationsTypes.UPDATE_CONFIGURATION_REQUEST,
    payload,
    cb
  })
};