// @flow
import type { StatelessFunctionalComponent } from 'react';
import styled from 'styled-components';
import { COMPUTER_SMALL_WIDTH, TABLET_WIDTH, CELLPHONE_WIDTH } from '../../helpers/constants';

const NavBarWrapper: StatelessFunctionalComponent<*> = styled.div`
  background-color: var(--white-color);

  .active-page {
    color: var(--text-color-2) !important;
    text-decoration: underline !important;
  }

  .navbar {
    &__container {
      min-height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 0;
      padding-bottom: 0;
      img{
        width: 170px;
      }
    }
    &__subcontainer{
      width: 69.2vw;
      min-height: 60px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-top: 0;
      
      padding-bottom: 0;

      font-family: var(--font-family-alt);
      img{
        width: 170px;
      }
    }
    &__links {
      font-size: 2.4em;
      font-weight: 500;
      a {
        color: var(--black-color);
        text-decoration: none;
        font-weight: bold;
      }

      .iconUser {
        height: 50px;
      }
    }

    &__links  * {
      margin-right: 30px;
    }

    &__right-section {
      display: flex;
      align-items: center;
      font-size: 1.8em;

      a {
        display: flex;
        align-items: center;
        color: var(--black-color);
        text-decoration: none;
        text-transform: uppercase;
      }

      .alert {
        background-color: orange;
        color: var(--white-color);
        border-radius: 12px;
        width: 18px;
        height: 18px;
        font-size: 0.5em;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin-left:10px;
        top: 28px;
      }

    }

    &__right-section > * {
      margin: 0 8px;
    }

    &__button * {
      background-color: var(--white-color);
      display: flex;
      margin-right: 10px;
    }

  }

  @media only screen and (max-width: ${COMPUTER_SMALL_WIDTH}px) {
    .navbar {
      &__container {
        padding: 0 25px;
      }

      &__links {
        font-size: 1.8em;
        margin-left: 100px;
      }

      &__links * {
        margin-right: 15px;
      }

      &__right-section {
        font-size: 1.6em;
        margin-right: 100px;
      }

      &__right-section > * {
        margin: 0 4px;
      }
    }
  }

  @media only screen and (max-width: ${TABLET_WIDTH}px) {
    .navbar {
      &__container {
        padding: 0 15px;
        min-height: 60px;
      }

      &__links {
        font-size: 1.8em;
        margin-left: 100px;
      }

      &__links * {
        margin-right: 5px;
      }

      &__right-section {
        font-size: 1.6em;
        margin-right: 100px;
      }
      &__subcontainer{
        width: 100vw;
      }
    }
  }

  @media only screen and (max-width: ${CELLPHONE_WIDTH}px) {
    .navbar {
      &__container {

      }
      &__subcontainer{
        width: 100vw;
        padding: 25px 15px 25px 15px;
        flex-direction: column;
      }
      &__right-section{
        margin-top: 20px;
      }
      &__links {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 10px;
      }

      &__right-section {
        margin-right: 10px;
      }

      &__right-section > * {
        margin: 0 6px;
      }
    }
  }
`;

export default NavBarWrapper;
