import { authTypes, loginTypes } from './types';

const initialState = {
  isLoggedIn: false,
  errorMessage: null,
  isLoading: false,
  resultMessage: null,
  user: null
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case authTypes.RESET_ERROR: {
      return {
        ...state,
        errorMessage: null
      };
    }
    case loginTypes.REQUEST: {
      return {
        ...state,
        errorMessage: null,
        isLoading: true
      };
    }
    case loginTypes.SUCCESS: {
      const { userName, ...rest } = payload;
      return {
        ...state,
        isLoggedIn: true,
        errorMessage: null,
        isLoading: false,
        user: {
          ...rest,
          name: userName
        }
      };
    }
    case loginTypes.FAILED: {
      return {
        ...state,
        isLoggedIn: false,
        isLoading: false,
        errorMessage: payload
      };
    }

    case authTypes.RESET_RESULT_MESSAGE: {
      return {
        ...state,
        resultMessage: null
      };
    }
    case loginTypes.LOGOUT_SUCCESS: {
      return {
        ...state,
        ...initialState,
        errorMessage: payload && payload.length > 0 ? payload : null
      };
    }
    default:
      return state;
  }
};

export default authReducer;
