import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import iconLogo from "../../assets/icons/logo-small.jpg";

import NavBarWrapper from "./NavBar.style";
import type { NavBarProps, NavBarState } from "./NavBar.types";

class NavBar extends Component<NavBarProps, NavBarState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { name, isAdmin, onLogoutClick } = this.props;

    return (
      <NavBarWrapper>
        <div className="navbar__container">
          <div className="navbar__subcontainer">
            <div className="navbar__links">
              {isAdmin && (
                <div className="d-flex align-items-center">
                  <h1 style={{ color: "var(--text-color-2)" }}>
                    Administrador
                  </h1>

                  <NavLink to="/admin/ordenes" activeClassName="active-page">
                    Órdenes
                  </NavLink>
                </div>
              )}

              {!isAdmin && (
                <div className="d-flex align-items-center">
                  <Link to="/" style={{'marginTop': '7px'}}>
                    <img src={require("../../assets/icons/logo-small.jpg")} alt="" />
                  </Link>
                </div>
              )}
            </div>

            <div className="navbar__right-section">
              <Link to="/">{name}</Link>
              &nbsp;
              <Link to="/login" onClick={onLogoutClick}>
                Salir
              </Link>
            </div>
          </div>
        </div>
      </NavBarWrapper>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.auth.isLoading,
  user: state.auth.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
