import statusTypes from './types';

export const initialState = {
  isLoading: false,
  errorMessage: null,
  resultMessage: null,
  invoices: [],
  paginationSize: 0,
  bankInvoicesSearch: null,
  ucfeInvoiceNro: null,
  successFinish: false
};

export default (state = initialState, action) => {
  
  switch (action.type) {
    case statusTypes.CLEAR_BANKINVOICES_STATE: {
      return { ...initialState };
    }
    case statusTypes.GET_BANK_INVOICES_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case statusTypes.SUCCESS_GET_BANK_INVOICES: {
      return {
        ...state,
        invoices: [...action.payload.invoices],
        paginationSize: action.payload.paginationSize,
        isLoading: false
      };
    }
    case statusTypes.FAILED_GET_BANK_INVOICES: {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload
      };
    }
    case statusTypes.GET_PRE_INVOICES_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case statusTypes.SUCCESS_GET_PRE_INVOICES: {
      return {
        ...state,
        invoices: [...action.payload.invoices],
        paginationSize: action.payload.paginationSize,
        isLoading: false
      };
    }
    case statusTypes.FAILED_GET_PRE_INVOICES: {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload
      };
    }
    case statusTypes.SUCCESS_GET_PAYMENTS: {
      return {
        ...state,
        payments: [...action.payload.payments],
        isLoading: false
      };
    }
    case statusTypes.BANK_INVOICES_SUCCESS: {
      return {
        ...state,
        bankInvoicesSearch: action.payload.bankInvoicesSearch,
        paginationSize: action.payload.paginationSize,
        isLoading: false
      };
    }
    case statusTypes.BANK_INVOICES_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload
      };
    }
    case statusTypes.BANK_INVOICES_ANNULATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        resultMessage: action.payload,
        successFinish: true,
        sendConfirmationResult: { ucfeInvoiceNro: action.payload.ucfeInvoiceNro, cfe_type: action.payload.cfe_type, receipt: action.payload.receipt }
      };
    }
    case statusTypes.BANK_INVOICES_ANNULATION_INIT: {
      return {
        ...state,
        isLoading: false,
        successFinish: false,
        resultMessage: null,
      };
    }
    case statusTypes.BANK_INVOICES_ANNULATION_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload
      };
    }
    case statusTypes.BANK_INVOICES_ANNULATION: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case statusTypes.BANK_INVOICES_REMOVE_AMOUNT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case statusTypes.BANK_INVOICES_REMOVE_AMOUNT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        resultMessage: action.payload,
        successFinish: true,
        sendConfirmationResult: { removedAmount: true,receipt: action.payload.receipt }
      };
    }
    case statusTypes.BANK_INVOICES_REMOVE_AMOUNT_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload
      };
    }
    case statusTypes.BANK_INVOICES_SHARE_PDF: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case statusTypes.BANK_INVOICES_SHARE_PDF_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        sendConfirmationResult: { shared: action.payload.shared, message: action.payload.message },
        resultMessage: action.payload.message,
        successFinish: true
      };
    }
    case statusTypes.BANK_INVOICES_SHARE_PDF_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload
      };
    }
    case statusTypes.BANK_INVOICES_EMIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case statusTypes.BANK_INVOICES_EMIT_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload
      };
    }
    case statusTypes.BANK_INVOICES_EMIT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        resultMessage: action.payload,
        successFinish: true,
        sendConfirmationResult: { ucfeInvoiceNro: action.payload.ucfeInvoiceNro, receipt: action.payload.receipt }
      };
    }
    case statusTypes.BANK_INVOICES_EMIT_INIT: {
      return {
        ...state,
        isLoading: false,
        successFinish: false,
        resultMessage: null,
      };
    }
    case statusTypes.BANK_INVOICES_MOD_BANK: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case statusTypes.BANK_INVOICES_MOD_BANK_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload
      };
    }
    case statusTypes.BANK_INVOICES_MOD_BANK_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        resultMessage: action.payload,
        successFinish: true,
        sendConfirmationResult: { ucfeInvoiceNro: action.payload.ucfeInvoiceNro, receipt: action.payload.receipt }
      };
    }
    case statusTypes.BANK_INVOICES_MOD_BANK_INIT: {
      return {
        ...state,
        isLoading: false,
        successFinish: false,
        resultMessage: null,
      };
    }
    case statusTypes.BANK_INVOICES_DECLINE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        resultMessage: action.payload,
        successFinish: true,
        sendConfirmationResult: { receiptId: action.payload.receiptId }
      };
    }
    case statusTypes.BANK_INVOICES_DECLINE_INIT: {
      return {
        ...state,
        isLoading: false,
        successFinish: false,
        resultMessage: null,
      };
    }
    case statusTypes.BANK_INVOICES_DECLINE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case statusTypes.BANK_INVOICES_DECLINE_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload
      };
    }
    case statusTypes.INVOICE_SHARE_PDF: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case statusTypes.INVOICE_SHARE_PDF_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        sendConfirmationResult: { shared: action.payload.shared, message: action.payload.message },
        resultMessage: action.payload.message,
        successFinish: true
      };
    }
    case statusTypes.INVOICE_SHARE_PDF_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload
      };
    }
    case statusTypes.SET_STATE: {
      return { ...initialState, ...action.payload };
    }
    case statusTypes.BANK_PRE_RECIBO_EMITIR: {
      return { ...initialState, ...action.payload };
    }
    case statusTypes.BANK_PRE_RECIBO_REJECT: {
      return { ...initialState, ...action.payload };
    }
    case statusTypes.CHECK_AND_EMIT: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};
