import types from './types';

export default {
  sendAccountability: () => ({
    type: types.SEND_ACCOUNTABILITY_REQUEST
  }),
  sendAccountabilitySuccess: () => ({
    type: types.SEND_ACCOUNTABILITY_SUCCESS
  }),
  sendAccountabilityFailed: () => ({
    type: types.SEND_ACCOUNTABILITY_FAILED
  }),
  createDeposit: (payload) => ({
    type: types.SEND_DEPOSIT_REQUEST,
    payload
  }),
  createDepositSuccess: () => ({
    type: types.SEND_DEPOSIT_SUCCESS
  }),
  createDepositFail: () => ({
    type: types.SEND_DEPOSIT_FAILED
  }),
  // Get internal  receipts
  getReceipts: (payload) => ({
    type: types.GET_ACCOUNTABILITY_RECEIPTS_REQUEST,
    payload
  }),
  getReceiptsSuccess: (payload) => ({
    type: types.GET_ACCOUNTABILITY_RECEIPTS_SUCCESS,
    payload
  }),
  getDepositsSuccess: (payload) => ({
    type: types.GET_ACCOUNTABILITY_DEPOSIT_SUCCESS,
    payload
  }),
  getReceiptsFailed: () => ({
    type: types.GET_ACCOUNTABILITY_RECEIPTS_FAILED
  }),
  getDepositsFailed: () => ({
    type: types.GET_ACCOUNTABILITY_DEPOSIT_FAILED
  }),
  getDeposits: () => ({
    type: types.GET_ACCOUNTABILITY_DEPOSITS
  }),
  // Select seller receipts
  addDeposit: () => ({
    type: types.ADD_DEPOSIT
  }),
  removeDeposit: (payload) => ({
    type: types.DELETE_DEPOSIT_REQUEST,
    payload
  }),
  getPostedAccountabilities:() => ({
    type: types.GET_POSTED_ACCOUNTABILITIES_REQUEST
  }),
  getPostedAccountabilitiesSuccess: (payload) => ({
    type: types.GET_POSTED_ACCOUNTABILITIES_SUCCESS,
    payload
  }),
};
