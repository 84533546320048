import { configurationsTypes } from './types';

const initialState = {
  exchange_rate: null,
  days_check_charge: null,
  days_check_emission: null,
  days_check_limit: null,
  days_check_expiration: null,
  days_check_charge_usd: null,
  days_check_charge_uyu: null,
  
  rounding_value: null, // hidden config

  detailedConfigurations: [] // detailed configurations

};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case configurationsTypes.SET_CONFIGURATIONS: {
      return {
        ...state,
        ...payload
      };
    }
    default:
      return state;
  }
};
