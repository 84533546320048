export const loginTypes = {
  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILED: 'LOGOUT_FAILED',
  REQUEST: 'LOGIN_REQUEST',
  SUCCESS: 'LOGIN_SUCCESS',
  FAILED: 'LOGIN_FAILED'
};

export const authTypes = {
  RESET_ERROR: 'RESET_ERROR',
  RESET_RESULT_MESSAGE: 'RESET_RESULT_MESSAGE'
};
