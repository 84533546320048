/* eslint-disable no-restricted-globals */
import moment from 'moment';

export const checkDates = (fields = [], data = { fecha: ['2020-10-09'] }) => {
  return new Promise((resolve, reject) => {
    let processedData = {};
    let undefinedData = [];
    fields.forEach((field) => {
      data[field].forEach((date, index) => {
        if (!isNaN(Date.parse(moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')))) {
          if (data[field] != null) {
            processedData = { ...processedData, [field]: data[field] };
          }
        } else {
          data[field] = null;
          undefinedData = [...undefinedData, field];
        }
      });
    });
    resolve({ data: processedData, undefinedData });
  });
};
