import * as React from 'react';
import Loadable from 'react-loadable';
import { Route } from 'react-router-dom';
import { Switch, Redirect, withRouter } from 'react-router';
import { connect } from 'react-redux';
import type { RoutesProps, ProtectedRouteProps } from './Router.types';
import ProtectedRoute from './ProtectedRoute';
import { Loader } from '../components/ui';
import { AREAS } from '../helpers';

const ProtectedRouteHOC = (LoadableComponent, availableAreas = null) => (props: ProtectedRouteProps) => (
  <ProtectedRoute {...props} availableAreas={availableAreas}>
    <LoadableComponent {...props} />
  </ProtectedRoute>
);

// ON Start up read:
// const token = localStorage.getItem('wurth_access_token');
// and validate token, if valid, dispatch action to Log In.
const Routes = ({ isLoggedIn }: RoutesProps) => (
  <React.Fragment>
    <Switch>
      <Redirect exact from="/" to="/login" />

      <Route
        exact
        path="/login"
        component={Loadable({
          loader: () => import('../screens/Auth/Login'),
          loading: () => <Loader cover />
        })}
      />

      <Route
        exact
        path="/home"
        component={ProtectedRouteHOC(
          Loadable({
            loader: () => import('../screens/Home'),
            loading: () => <Loader cover />
          }),
          Object.values(AREAS)
        )}
      />
      
      <Route
        exact
        path="/upload-deposite-records"
        component={ProtectedRouteHOC(
          Loadable({
            loader: () => import('../screens/Deposits'),
            loading: () => <Loader cover />
          }),
          [AREAS.SALES, AREAS.CREDITS, AREAS.ADMINISTRATION]
        )}
      />
      <Route
        exact
        path="/upload-bank-records"
        component={ProtectedRouteHOC(
          Loadable({
            loader: () => import('../screens/LoadBankRecords'),
            loading: () => <Loader cover />
          }),
          [AREAS.SALES, AREAS.CREDITS, AREAS.ADMINISTRATION]
        )}
      />
      <Route
        exact
        path="/register-bank-invoices"
        component={ProtectedRouteHOC(
          Loadable({
            loader: () => import('../screens/RegisterBankInvoices/'),
            loading: () => <Loader cover />
          }),
          [AREAS.SALES, AREAS.FINANCE, AREAS.CREDITS, AREAS.ADMINISTRATION]
        )}
      />
      <Route
        exact
        path="/state-account"
        component={ProtectedRouteHOC(
          Loadable({
            loader: () => import('../screens/Account/'),
            loading: () => <Loader cover />
          }),
          [AREAS.SALES, AREAS.CREDITS, AREAS.ADMINISTRATION]
        )}
      />
      <Route
        exact
        path="/search-pre-recibos"
        component={ProtectedRouteHOC(
          Loadable({
            loader: () => import('../screens/SearchPreRecibos/'),
            loading: () => <Loader cover />
          }),
          [AREAS.SALES, AREAS.FINANCE, AREAS.CREDITS, AREAS.ADMINISTRATION]
        )}
      />
      <Route
        exact
        path="/reporte-cobranza"
        component={ProtectedRouteHOC(
          Loadable({
            loader: () => import('../screens/ReporteCobranza/'),
            loading: () => <Loader cover />
          }),
          [AREAS.ADMINISTRATION]
        )}
      />
      <Route
        exact
        path="/search-bank-invoices"
        component={ProtectedRouteHOC(
          Loadable({
            loader: () => import('../screens/SearchBankInvoices/'),
            loading: () => <Loader cover />
          }),
          [AREAS.SALES, AREAS.FINANCE, AREAS.CREDITS, AREAS.ADMINISTRATION]
        )}
      />
      <Route
        exact
        path="/assign-positive-balance"
        component={ProtectedRouteHOC(
          Loadable({
            loader: () => import('../screens/AssignPositiveBalance/'),
            loading: () => <Loader cover />
          }),
          [AREAS.SALES, AREAS.FINANCE, AREAS.CREDITS, AREAS.ADMINISTRATION]
        )}
      />
      <Route
        exact
        path="/seller-receipts"
        component={ProtectedRouteHOC(
          Loadable({
            loader: () => import('../screens/SellerReceipts/'),
            loading: () => <Loader cover />
          }),
          [AREAS.SALES, AREAS.FINANCE, AREAS.CREDITS, AREAS.ADMINISTRATION]
        )}
      />
      <Route
        exact
        path="/send-accountability"
        component={ProtectedRouteHOC(
          Loadable({
            loader: () => import('../screens/SendAccountability/'),
            loading: () => <Loader cover />
          }),
          [AREAS.SALES, AREAS.FINANCE, AREAS.CREDITS, AREAS.ADMINISTRATION]
        )}
      />
      <Route
        exact
        path="/accountabilities"
        component={ProtectedRouteHOC(
          Loadable({
            loader: () => import('../screens/Accountabilities/'),
            loading: () => <Loader cover />
          }),
          [AREAS.SALES, AREAS.FINANCE, AREAS.CREDITS, AREAS.ADMINISTRATION]
        )}
      />
      <Route
        exact
        path="/configurations"
        component={ProtectedRouteHOC(
          Loadable({
            loader: () => import('../screens/Configurations/'),
            loading: () => <Loader cover />
          }),
          [AREAS.ADMINISTRATION]
        )}
      />
    </Switch>
  </React.Fragment>
);

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  isAdmin: state.auth.user?.isAdmin
});

export default withRouter(connect(mapStateToProps)(Routes));
