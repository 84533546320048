import { takeLatest, call, put } from 'redux-saga/effects';
import { configurationsTypes as types } from './types';
import configurationsActions from './actions';

import ConfigurationApi from '../../api/configurations';
import { toast } from 'react-toastify';


function* getDetailedConfigurations () {
  try{ 
    const { success, configurations: detailedConfigurations } = yield call(ConfigurationApi.getConfigurations);

    if(success) {
      yield put(configurationsActions.setConfigurations({ detailedConfigurations }))
    }
    
  }catch(e){
    console.log(e);
  }
}

function* updateConfiguration ({ payload, cb }) {
  try{ 
    const [[key, value]] = Object.entries(payload);
    const { success } = yield call(ConfigurationApi.updateConfiguration, { key, value });

    if(success){
      toast.success('Se ha guardado la configuracion configuración');
    }
    else toast.error('Ocurrió un error al guardar la configuración')

    yield call(cb);
  }catch(e){
    yield call(cb);

  }
}

export default function* configurationsSaga () {
  yield takeLatest(types.GET_CONFIGURATIONS_REQUEST, getDetailedConfigurations);
  yield takeLatest(types.UPDATE_CONFIGURATION_REQUEST, updateConfiguration);
}
