// @flow
import type { StatelessFunctionalComponent } from 'react';
import styled from 'styled-components';

const CardWrapper: StatelessFunctionalComponent<*> = styled.div`
  padding: 30px 0px;
  text-align: center;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 1300px;
  margin-bottom: 2rem !important;

  &.Card {
    &--small {
      max-width: 600px;
    }
    &--medium {
      max-width: 900px;
    }
    &--big {
      max-width: 1200px;
    }

    &--fluid {
      padding: 0;
      text-align: left;
      margin: 0;
      max-width: 100%;

      .Card-container {
        padding: 0;
      }

      .Card-box {
        max-width: 100%;
      }
    }
  }

  .Card {
    &-container {
      display: block;
      width: 100%;
      padding: 30px 12px;
    }

    &-box {
      max-width: 1200px;
      padding: 0px 12px;
      border-radius: 0px 0px 0px 0px;
      box-shadow: 0 2px 4px 0 rgba(50, 50, 50, 0.12);
      border-top: 1px solid var(--line-color);
      background-color: var(--white-color);
    }

    &-header {
      font-size: 3.2em;
      font-weight: 500;
      color: var(--text-color-1);
      margin: 0px;
      padding: 60px;
    }
  }
`;

export default CardWrapper;
