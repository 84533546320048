import { connect } from 'react-redux';
import NavbarComponent from '../../components/NavBar';
import authActions from '../../ducks/auth/actions';

const mapStateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn,
  isAdmin: state.auth.user?.isAdmin,
  name: state.auth.user ? state.auth.user.name : 'Sin Nombre'
});

const mapDispatchToProps = {
  onLogoutClick: () => authActions.logout(),
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarComponent);
