import React, { useEffect } from 'react';
import ModalProps from './Modal.types';
import ModalWrapper from './Modal.style';

const Modal = (props: ModalProps) => {
  const {
    children,
    show = false,
    close = () => {},
    title,
    className
  } = props;

  useEffect(() => {
    const body = document.querySelector('body');

    if (show) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'auto';
    }

    return () => {
      body.style.overflow = 'auto';
    };
  }, [show]);

  function handleModalWrapperClick() {
    close();
  }

  function handleModalInnerClick(event) {
    event.stopPropagation();
  }

  return (
    <ModalWrapper
      isOpen={show}
      onClick={handleModalWrapperClick}
      role="dialog"
      className={`Modal ${className ? className : ''}`}
    >
      <div className="Modal-inner" onClick={handleModalInnerClick} role="document">
        <div className="Modal-header">
          <div className="Modal-title">{title}</div>
          <button
            type="button"
            aria-label="Cerrar"
            className="Modal-close"
            onClick={() => close()}
          >
            ╳
          </button>
        </div>

        <div className="Modal-body">{children}</div>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
