export default {
  // SEND ACCOUNTABILITY REQUEST
  SEND_ACCOUNTABILITY_REQUEST: 'SEND_ACCOUNTABILITY_REQUEST',
  SEND_ACCOUNTABILITY_SUCCESS: 'SEND_ACCOUNTABILITY_SUCCESS',
  SEND_ACCOUNTABILITY_FAILED: 'SEND_ACCOUNTABILITY_FAILED',
  SEND_DEPOSIT_REQUEST: 'SEND_DEPOSIT_REQUEST',
  SEND_DEPOSIT_SUCCESS: 'SEND_DEPOSIT_SUCCESS',
  SEND_DEPOSIT_FAILED: 'SEND_DEPOSIT_FAILED',
  DELETE_DEPOSIT_REQUEST: 'DELETE_DEPOSIT_REQUEST',
  DELETE_DEPOSIT_SUCCESS: 'DELETE_DEPOSIT_SUCCESS',
  GET_POSTED_ACCOUNTABILITIES_REQUEST: 'GET_POSTED_ACCOUNTABILITIES_REQUEST',
  GET_POSTED_ACCOUNTABILITIES_SUCCESS: 'GET_POSTED_ACCOUNTABILITIES_SUCCESS',
  DELETE_DEPOSIT_FAILED: 'DELETE_DEPOSIT_FAILED',
  // GET SELLER RECEIPTS
  GET_ACCOUNTABILITY_RECEIPTS_REQUEST: 'GET_ACCOUNTABILITY_RECEIPTS_REQUEST',
  GET_ACCOUNTABILITY_RECEIPTS_SUCCESS: 'GET_ACCOUNTABILITY_RECEIPTS_SUCCESS',
  GET_ACCOUNTABILITY_RECEIPTS_FAILED: 'GET_ACCOUNTABILITY_RECEIPTS_FAILED',
  GET_ACCOUNTABILITY_DEPOSIT_SUCCESS: 'GET_ACCOUNTABILITY_DEPOSIT_SUCCESS',
  GET_ACCOUNTABILITY_DEPOSIT_FAILED: 'GET_ACCOUNTABILITY_DEPOSIT_FAILED',
  // SELECT & REMOVE Seller Receipts
  ADD_DEPOSIT: 'ADD_DEPOSIT',
  REMOVE_DEPOSIT: 'REMOVE_DEPOSIT',
  GET_ACCOUNTABILITY_DEPOSITS: 'GET_ACCOUNTABILITY_DEPOSITS',

  // for reset flag values
  SET_SEND_ACCOUNTABILITY_STATE: 'SET_SEND_ACCOUNTABILITY_STATE'
};
