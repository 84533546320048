import styled from 'styled-components';

const ModalWrapper = styled.div`
  display: none;
  position: fixed;
  height: auto;
  text-align: center;
  align-items: flex-start;
  justify-content: flex-end;
  z-index: 9999;
  top: 25px;
  left: 0;
  right: 50px;
  bottom: 0;

  &.Modal {
    &--small {
      .Modal-inner {
        max-width: 400px;
      }
    }
  }

  .Modal {
    &-inner {
      background-color: #fff;
      max-height: 90vh;
      max-width: 800px;
      padding: 0rem;
      width: 100%;
      border-radius: 12px;
      box-shadow: 0 20px 20px rgba(0, 0, 0, .15);
      overflow-y: auto;
      margin-top: 50px;
      width: 100%;
    }

    &-body {
      font-size: 16px;

      p {
        margin-bottom: 1rem;
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  ${props => props.isOpen && (`
    display: flex;
  `)}
`;

export default ModalWrapper;
