import statusTypes from './types';

export default {
  setState: (payload) => ({
    type: statusTypes.SET_ACCOUNT_CLIENT_FORM_STATE,
    payload
  }),
  setClientsSuccess: (payload) => ({
    type: statusTypes.SET_CLIENTS_SUCCESS,
    payload
  }),
  setClientsRequest: (payload) => ({
    type: statusTypes.SET_CLIENTS_REQUEST,
    payload
  }),
  setClientsFail: (payload) => ({
    type: statusTypes.SET_CLIENTS_FAIL,
    payload
  }),
  setClientsDataRequest: (payload) => ({
    type: statusTypes.SET_CLIENTS_DATA_REQUEST,
    payload
  }),

  setAccountStatusRequest: (payload, cb = () => {}) => ({
    type: statusTypes.SET_ACCOUNT_REQUEST,
    payload,
    cb
  }),
  setAccountSuccess: (payload) => ({
    type: statusTypes.SET_ACCOUNT_SUCCESS,
    payload
  }),
  setAccountFail: (payload) => ({
    type: statusTypes.SET_ACCOUNT_FAIL,
    payload
  }),

  sendMailRequest: (payload) => ({
    type: statusTypes.SEND_MAIL_REQUEST,
    payload
  }),
  sendMailSuccess: (payload) => ({
    type: statusTypes.SEND_MAIL_SUCCESS,
    payload
  }),
  sendMailFailed: (payload) => ({
    type: statusTypes.SEND_MAIL_FAILED,
    payload
  }),
  getAccountStatusPDFRequest: (payload) => ({
    type: statusTypes.GET_ACCOUNT_STATUS_PDF_REQUEST,
    payload
  }),
  getAccountStatusPDFSuccess: (payload) => ({
    type: statusTypes.GET_ACCOUNT_STATUS_PDF_SUCCESS,
    payload
  }),
  getAccountStatusPDFFail: (payload) => ({
    type: statusTypes.GET_ACCOUNT_STATUS_PDF_FAIL,
    payload
  }),
  resetTheState: () => ({
    type: statusTypes.RESET_THE_ACCOUNT_STATE
  })
};
