export default {
  /* status types */
  CLEAR_BANKRECORDS_STATE: 'CLEAR_BANKRECORDS_STATE',
  RESET_STATE: 'RESET_STATE',

  // records upload
  BANK_RECORD_SUCCESS_UPLOAD: 'BANK_RECORD_SUCCESS_UPLOAD',
  BANK_RECORD_FAILED_UPLOAD: 'BANK_RECORD_FAILED_UPLOAD',
  BANK_RECORD_UPLOAD_REQUEST: 'BANK_RECORD_UPLOAD_REQUEST',
  LOADING: 'LOADING',
  BANK_RECORD_UPLOAD_COBRANZA: 'BANK_RECORD_UPLOAD_COBRANZA',
  BANK_RECORD_COBRANZA_SUCCESS: 'BANK_RECORD_COBRANZA_SUCCESS',
  BANK_RECORD_COBRANZA_FAILED: 'BANK_RECORD_COBRANZA_FAILED',
  CLIENT_REQUEST: 'CLIENT_REQUEST',
  CLIENT_REQUEST_SUCCESS: 'CLIENT_REQUEST_SUCCESS',
  CLIENT_REQUEST_FAILED: 'CLIENT_REQUEST_FAILED',

  // records retrieve
  GET_BANK_RECORDS_REQUEST: 'GET_BANK_RECORDS_REQUEST',
  SUCCESS_GET_BANK_RECORDS: 'SUCCESS_GET_BANK_RECORDS',
  FAILED_GET_BANK_RECORDS: 'FAILED_GET_BANK_RECORDS',

  // SEND RECORDS 
  SEND_BANK_RECORDS_REQUEST: 'SEND_BANK_RECORDS_REQUEST',
  SUCCESS_SEND_BANK_RECORDS: 'SUCCESS_SEND_BANK_RECORDS',
  FAILED_SEND_BANK_RECORDS: 'FAILED_SEND_BANK_RECORDS',

  // delete bank record
  DELETE_BANK_RECORD_REQUEST: 'DELETE_BANK_RECORD_REQUEST',
  DELETE_BANK_RECORD_SUCCESS: 'DELETE_BANK_RECORD_SUCCESS',
  DELETE_BANK_RECORD_FAILED: 'DELETE_BANK_RECORD_FAILED',

  // Messages
  AN_ERROR_HAS_OCURRED: 'Ha ocurrido un error, vuelva a intentarlo más tarde.',
  SUCCESS: 'SUCCESS',

  SEND_CONFIRMATION: 'SEND_CONFIRMATION',
  CONFIRMATION_SUCCESS: 'CONFIRMATION_SUCCESS',
  CONFIRMATION_FAIL: 'CONFIRMATION_FAIL',

  GENERATE_ADENDA: 'GENERATE_ADENDA',
  GENERATE_ADENDA_SUCCESS: 'GENERATE_ADENDA_SUCCESS',
  GENERATE_ADENDA_FAIL: 'GENERATE_ADENDA_FAIL',

  CLIENT_ACCOUNT_REQUEST: 'CLIENT_ACCOUNT_REQUEST',
  CLIENT_ACCOUNT_SUCCESS: 'CLIENT_ACCOUNT_SUCCESS',
  CLIENT_ACCOUNT_FAILED: 'CLIENT_ACCOUNT_FAILED'
};
