// @flow
import type { StatelessFunctionalComponent } from 'react';
import styled from 'styled-components';

const InputDateWrapper: StatelessFunctionalComponent<*> = styled.div`
  input {
    background-color: transparent;
  }

  .input-error {
    input {
      border-bottom: 1px solid var(--input-error-color) !important;
    }
    span {
      color: var(--input-error-color) !important;
    }
  }

  .input-line {
    display: flex;
    padding: 5px 0px;
    position: relative;
    label > input {
      padding-left: 8px;
      padding-top: 20px;
    }
    label > span {
      padding-left: 8px;
      font-size: 1.5em;
      color: var(--text-color-2);
    }
  }

  .input-img-container {
    margin: 16px 12px 12px 0px;
    width: 30px;
    height: 25px;
  }

  .input-img {
    width: 25px;
    height: 25px;
  }

  .alert-img {
    width: 25px;
    height: 25px;
    align-self: center;
    padding-bottom: 6px;
    position: absolute;
    right: 0;
  }

  .alert-img-hide {
    display: none;
  }

  .react-datepicker-wrapper input {
    border: 0;
    font-family: inherit;
    font-size: 1rem !important;
    width: 100%;
    padding: 12px 0 8px 0;
    border-bottom: 1px solid black;
  }

  label {
    position: relative;

    input {
      padding-left: 8px !important;
      padding-bottom: 8px !important;
      padding-top: 20px !important;
    }
  }

  span {
    padding: 14px 0 13px 0;
    transition: font-size 200ms, padding 200ms;
    pointer-events: none;
    top: 0;
    left: 0;
    z-index: 1;
    position: absolute;
    padding-left: 8px;
    font-size: 0.825rem !important;
    color: var(--text-color-2);
  }

  .react-datepicker {
    font-size: 0.825rem !important;
  }

  .react-datepicker__month-container {
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
  }

  .react-datepicker__day-names {
    padding: 6px !important;
  }

  .react-datepicker__day {
    padding: 4px !important;
  }

  .react-datepicker__current-month {
    padding: 6px !important;
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
    margin-top: -6px !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range {
    background-color: var(--blue-color) !important;
  }

  .react-datepicker__month--selecting-range .react-datepicker__day--in-range {
    color: #fff !important;
    background-color: var(--blue-light-color) !important;
  }
`;

export default InputDateWrapper;
