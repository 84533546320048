export default {
  CLEAR_ACCOUNT_STATE: 'CLEAR_ACCOUNT_STATE',
  SET_ACCOUNT_CLIENT_FORM_STATE: 'SET_ACCOUNT_CLIENT_FORM_STATE',
  SET_CLIENTS_REQUEST: 'SET_CLIENTS_REQUEST',
  SET_CLIENTS_SUCCESS: 'SET_CLIENTS_SUCCESS',
  SET_CLIENTS_FAIL: 'SET_CLIENTS_FAIL',
  SET_CLIENTS_DATA_REQUEST: 'SET_CLIENTS_DATA_REQUEST',
  SET_ACCOUNT_REQUEST: 'SET_ACCOUNT_REQUEST',
  SET_ACCOUNT_SUCCESS: 'SET_ACCOUNT_SUCCESS',
  SET_ACCOUNT_FAIL: 'SET_ACCOUNT_FAIL',

  SEND_MAIL_REQUEST: 'SEND_MAIL_REQUEST',
  SEND_MAIL_SUCCESS: 'SEND_MAIL_SUCCESS',
  SEND_MAIL_FAILED: 'SEND_MAIL_FAILED',

  GET_ACCOUNT_STATUS_PDF_REQUEST: 'GET_ACCOUNT_STATUS_PDF_REQUEST',
  GET_ACCOUNT_STATUS_PDF_SUCCESS: 'GET_ACCOUNT_STATUS_PDF_SUCCESS',
  GET_ACCOUNT_STATUS_PDF_FAIL: 'GET_ACCOUNT_STATUS_PDF_FAIL',

  RESET_THE_ACCOUNT_STATE: 'RESET_THE_ACCOUNT_STATE'
};
