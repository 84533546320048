export default {
  GET_BANK_RECORDS_WITH_POSITIVE_BALANCE_REQUEST: 'GET_BANK_RECORDS_WITH_POSITIVE_BALANCE_REQUEST',
  GET_BANK_RECORDS_WITH_POSITIVE_BALANCE_SUCCESS: 'GET_BANK_RECORDS_WITH_POSITIVE_BALANCE_SUCCESS',
  GET_BANK_RECORDS_WITH_POSITIVE_BALANCE_FAILED: 'GET_BANK_RECORDS_WITH_POSITIVE_BALANCE_FAILED',

  SET_INTERNAL_RECEIPT_WITH_POSITIVE_BALANCE: 'SET_INTERNAL_RECEIPT_WITH_POSITIVE_BALANCE',
  
  // FORM
  SET_ASSIGN_BANK_RECORD_POSITIVE_BALANCE_SET_FORM: 'SET_ASSIGN_BANK_RECORD_POSITIVE_BALANCE_SET_FORM',

  ASSIGN_BANK_RECORD_POSITIVE_BALANCE_SEND_CONFIRMATION_REQUEST:
    'ASSIGN_BANK_RECORD_POSITIVE_BALANCE_SEND_CONFIRMATION_REQUEST',
  ASSIGN_BANK_RECORD_POSITIVE_BALANCE_SEND_CONFIRMATION_SUCCESS:
    'ASSIGN_BANK_RECORD_POSITIVE_BALANCE_SEND_CONFIRMATION_SUCCESS',
  ASSIGN_BANK_RECORD_POSITIVE_BALANCE_SEND_CONFIRMATION_FAILED:
    'ASSIGN_BANK_RECORD_POSITIVE_BALANCE_SEND_CONFIRMATION_FAILED',

  // Clear state
  ASSIGN_BANK_RECORD_POSITIVE_BALANCE_RESET_STATE: 'ASSIGN_BANK_RECORD_POSITIVE_BALANCE_RESET_STATE'
};
