// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import ButtonWrapper from './Button.style';
import type { ButtonProps } from './Button.types';
import { Loader } from '..';

const Button = (props: ButtonProps) => {
  const {
    children,
    className,
    onClick,
    disabled,
    secondary,
    terciary,
    plain,
    link,
    block,
    isLoading,
    hidden,
    to,
    ...rest
  } = props;

  const buttonProps = to ? {} : { type: 'button' };
  const linkProps = to ? { as: Link, to } : {};

  /**
   * TODO: Cuando tiene enlace (Link de react-router) muestra un warning
   * https://github.com/styled-components/styled-components/issues/1198
   */

  return (
    <ButtonWrapper
      className={className}
      disabled={disabled}
      secondary={secondary ? 1 : undefined}
      terciary={terciary}
      link={link ? 1 : undefined}
      plain={plain ? 1 : undefined}
      block={block}
      hidden={hidden}
      isloading={isLoading ? '1' : undefined}
      onClick={!isLoading ? onClick : null}
      {...buttonProps}
      {...linkProps}
      {...rest}
    >
      <span>{children}</span>
      {isLoading && (
        <div>
          <Loader {...rest} short small />
        </div>
      )}
    </ButtonWrapper>
  );
};

export default Button;
