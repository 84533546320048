import { call, put, select, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import Accountabilities from '../../api/accountabilities';
import types from './types';
import actions from './actions';
export function* deleteDeposit({ payload }){
  try {
    
    var deposit_id = payload
    
    const { success, processedDeposits } = yield call(Accountabilities.deleteDeposit, {deposit_id} );
    if (success) {
      yield put(actions.getDeposits());
      yield call(
        toast.success, 
        `Se ha borrado el deposito correctamente'`, 
        {
          position: toast.POSITION.BOTTOM_LEFT
      
        }
      );
    } else {
      yield put(actions.sendAccountabilityFailed());
      yield call(toast.error, 'Ocurrió un error al borrar el deposito' , {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  } catch (e) {
    yield put(actions.sendAccountabilityFailed());
    yield call(toast.error, 'Ocurrió un error al borrar el deposito', {
      position: toast.POSITION.BOTTOM_LEFT
    });
  }
}
export function* createDeposit({ payload }){
  try {
    var deposits = [payload]
    const { success, processedDeposits } = yield call(Accountabilities.createDeposit, {deposits} );
    
    if (success) {
      yield put(actions.sendAccountabilitySuccess());
      yield call(
        toast.success, 
        `Se ha creado el deposito correctamente'`, 
        {
          position: toast.POSITION.BOTTOM_LEFT
      
        }
      );
      yield put(actions.getDeposits());
      
    } else {
      yield put(actions.sendAccountabilityFailed());
      yield call(toast.error, 'Ocurrió un error al crear el deposito' , {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  } catch (e) {
    yield put(actions.sendAccountabilityFailed());
    yield call(toast.error, 'Ocurrió un error al crear el deposito' , {
      position: toast.POSITION.BOTTOM_LEFT
    });
  }
}
export function* sendAccountability() {
  try {
    const { deposits } = yield select((state) => state.sendAccountability);
    const { success } = yield call(Accountabilities.sendAccountability, { deposits });
    if (success) {
      yield put(actions.sendAccountabilitySuccess());
      yield call(toast.success, deposits && deposits.lenth > 0 ? 'Deposito Creado' : 'Rendición de cuenta cerrada correctamente', {
        position: toast.POSITION.BOTTOM_LEFT
      });
      yield put(actions.getReceipts({ page: 0 }));
    } else {
      yield put(actions.sendAccountabilityFailed());
      yield call(toast.error, deposits && deposits.lenth > 0 ? 'Ocurrió un error al crear el deposito' :  'Ocurrió un error al generar la rendición de cuenta', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  } catch (e) {
    yield put(actions.sendAccountabilityFailed());
    yield call(toast.error, 'Ocurrió un error al generar la rendición de cuenta', {
      position: toast.POSITION.BOTTOM_LEFT
    });
  }
}
export function* getReceipts({ payload }) {
  try {
    const { page = 0 } = payload;
    const pageSize = 10;
    const { success, receipts, paginationSize } = yield call(Accountabilities.getAccountabilityReceitps, {
      page,
      pageSize,
      ...payload
    });
    if (success) yield put(actions.getReceiptsSuccess({ receipts, paginationSize }));
    else yield put(actions.getReceiptsFailed());
  } catch (e) {
    yield put(actions.getReceiptsFailed());
  }
}
export function* getDeposits(action) {
  try {
    const { success, desposits, paginationSize } = yield call(Accountabilities.getDeposits);
    if (success) yield put(actions.getDepositsSuccess({ allDeposits: desposits }));
    else yield put({type: actions.getDepositsFailed.type,  payload : { errorMessage: "Error obteniendo depositos"}});
  } catch (e) {
    yield put(actions.getReceiptsFailed());
  }
}
function* getAccountabilities(){
  try {
    const { success, accs, paginationSize } = yield call(Accountabilities.getPostedAccountabilities);
    let formattedAccs;
    if (success){
      formattedAccs = [{
        id: 0,
        text:'Rendicion futura'
      }]
      formattedAccs = formattedAccs.concat(
        accs.map((element)=>{
          // Aca se deberia de filtrar aquellos que ya estan completos en cuanto a depositos, es decir que ya tienen tanto su saldo en UYU como en USD los depositos
          return {
            id:element.id,
            text: new Date(element.createdAt).toLocaleDateString("en-GB") + ' - UYU: ' + element.uyu_cash_sum + ' - USD ' + element.usd_cash_sum
          }
        }
      ))
      yield put(actions.getPostedAccountabilitiesSuccess({ postedAcc:formattedAccs, allAccs: accs}));
    }else{
      yield put({type: actions.getDepositsFailed.type,  payload : { errorMessage: "Error obteniendo depositos"}});
    }
     
    
  } catch (e) {
    yield put(actions.getReceiptsFailed());
  }
}

export default function* SendAccountabilitySaga() {
  yield takeLatest(types.SEND_ACCOUNTABILITY_REQUEST, sendAccountability);
  yield takeLatest(types.GET_ACCOUNTABILITY_RECEIPTS_REQUEST, getReceipts);
  yield takeLatest(types.ADD_DEPOSIT,sendAccountability);
  yield takeLatest(types.GET_ACCOUNTABILITY_DEPOSITS,getDeposits);
  yield takeLatest(types.SEND_DEPOSIT_REQUEST,createDeposit);
  yield takeLatest(types.DELETE_DEPOSIT_REQUEST,deleteDeposit);
  yield takeLatest(types.GET_POSTED_ACCOUNTABILITIES_REQUEST, getAccountabilities)
}
