import axios from '../axiosConfig';

export default {
  getClients: (request) => axios.post(`/clients-group/clientList`, request),
  getClientInvoices: (request) => axios.post(`/clients-group/clientInvoice`, request),
  getPayments: (request) => axios.post(`/clients-group/allPayments`, request),
  getClientByGroupId: (groupId) => axios.get(`/clients/get-clients?group_id=${groupId}`),
  getAccountStatus: (request) => axios.post(`/clients-group/getAccountStatus`, request),
  sendMail: (request) => axios.post('/clients-group/sendMail', request),
  generateAccountStatusPdf: (request) => axios.post(`/clients-group/generateAccountStatusPdf`, request),
  downloadAccountStatusPDF: (fileName) => axios.get(`/clients-group/downloadAccountStatusPDF?fileName=${fileName}`,
  {
    responseType: 'blob',
    headers: {
      'Accept': 'application/pdf'
    }
  }).then(response => response.data),
  getClientAccounts: (request) => axios.post(`/clients/clientAccountList`, request),
  isClientBloqued: (request) => axios.post(`/clients/clientBloqued`, request),
};
