import type { StatelessFunctionalComponent } from 'react';
import styled from 'styled-components';

const Wrapper: StatelessFunctionalComponent<*> = styled.div`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    line-height: normal;
  }
  h3 {
    text-align: center;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  margin: 0;
  font-size: 10px;
  font-family: 'WuerthExtraBoldCond', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  letter-spacing: normal;

  /*NoSelect*/
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */


  .Toastify__toast-container {
    width: 400px;
  }
  
  .Toastify__toast {
    font-size: 20px;
    padding: 10px;
    font-weight: bold;
    border-radius: 6px;
    margin-bottom: 5px;
  }

  .Toastify__toast--success {
    background-color: #00c77c;
  }
`;

export default Wrapper;
