import types from './types';

const initialState = {
  accountabilityDetail: null, // show accountability detail ft
  allDeposits:[],
  accountabilities: null,
  paginationSize: 0,

  filters:{ 
    sellerId: '',
    sellerName: '',
    creationDateFrom: '',
    creationDateTo: '',
    approbationDateFrom: '',
    approbationDateTo: '',
    status: ''
  },

  successGetAccountabilities: null
};

export default function accountabilitiesReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.GET_ACCOUNTABILITIES_SUCCESS: {
      const { paginationSize, accountabilities } = payload;
      return { ...state, accountabilities, paginationSize, successGetAccountabilities: true };
    }
    case types.GET_ACCOUNTABILITIES_FAILED: {
      return { ...state, successGetAccountabilities: false };
    }

    case types.GET_ACCOUNTABILITY_DETAIL_SUCCESS: {
      return { ...state, accountabilityDetail: payload };
    }
    
    case types.GET_ACCOUNTABILITIES_REQUEST: {
      const { page, ...flts } = payload;
      return {...state, filters:{ ...state.filters, ...flts } };
    }

    default:
      return state;
  }
}
