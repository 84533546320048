import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { BankRecords, AssignBankRecordsPositiveBalance } from '../../api';

import types from './types';
import actions from './actions';

export function* getBankRecordsWithPositiveBalance({ payload }) {
  try {
    const filters = {};
    Object.keys(payload).map((key) => {
      if (payload[key]) filters[key] = payload[key];
    });
    const { page } = payload;
    const pageSize = 10;
    const { data } = yield call(BankRecords.getBankRecordsWithPositiveBalance, { ...filters, page, pageSize });
    if (data.success) {
      const { bankRecords, paginationSize } = data;
      yield put(actions.getBankRecordsWithPositiveBalanceSuccess({ bankRecords, paginationSize }));
    } else {
      yield put(actions.getBankRecordsWithPositiveBalanceFailed());
    }
  } catch (e) {
    yield put(actions.getBankRecordsWithPositiveBalanceFailed());
  }
}
export function* assignPositiveBalanceSendConfirmation() {
  try {

    const {
      selectedFeeInvoices
    } = yield select((state) => state.assignBankRecordsPositiveBalance.form);
    const {
      internalReceipt
    } = yield select((state) => state.assignBankRecordsPositiveBalance);

    const request = { internalReceiptId: internalReceipt.id, selectedFeeInvoices };
    const {
      data: { success }
    } = yield call(AssignBankRecordsPositiveBalance.assignPositiveBalanceSendConfirmation, request);
    if (success) {
      yield put(actions.sendConfirmationSuccess());
      yield call(toast.success, 'Se han asignado el saldo a favor correctamente');
    } else {
      yield put(actions.sendConfirmationFailed());
      yield call(toast.error, 'Ha ocurrido un error al asignar el saldo a favor');
    }
  } catch (e) {
    yield put(actions.sendConfirmationFailed());
  }
}

export default function* assignBankRecordsPositiveBalance() {
  yield takeLatest(types.GET_BANK_RECORDS_WITH_POSITIVE_BALANCE_REQUEST, getBankRecordsWithPositiveBalance);
  yield takeLatest(
    types.ASSIGN_BANK_RECORD_POSITIVE_BALANCE_SEND_CONFIRMATION_REQUEST,
    assignPositiveBalanceSendConfirmation
  );
}
