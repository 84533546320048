import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import iconCalendar from '../../../assets/icons/icon-calendar.svg';
import type { InputDateProps } from './InputDate.types';
import InputDateWrapper from './InputDate.style';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';

registerLocale('es', es);

const InputDate = (props: InputDateProps) => {
  const { id, label, containerStyle, ...rest } = props;

  return (
    <InputDateWrapper style={containerStyle}>
      <div className="input-line">
        <div className="input-img-container">
          <img src={iconCalendar} alt="" className="input-img" />
        </div>
        <label htmlFor={id}>
          <DatePicker dateFormat="dd/MM/yyyy" locale="es" {...rest} />
          <span>{label}</span>
        </label>
      </div>
    </InputDateWrapper>
  );
};

export default InputDate;
