import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootSaga from './sagas';
import createRootReducer from './reducers';
import authActions from './auth/actions';
import axios from '../api/axiosConfig';

export const history = createBrowserHistory({ basename: process.env.REACT_APP_BASENAME });
const sagaMiddleware = createSagaMiddleware();
const middlewares = [routerMiddleware(history), sagaMiddleware];
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const persistConfig = {
  key: 'wurth',
  storage,
  whitelist: ['auth', 'configurations'],
  migrate: (state) => {
    const oldAuth = state ? state.auth : {};
    const newState = {
      ...state,
      auth: {
        ...oldAuth,
        errorMessage: null,
        resultMessage: null,
        isLoading: false
      }
    };
    return Promise.resolve(newState);
  }
};

const rootReducer = createRootReducer(history);
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  persistedReducer, // root reducer with router state
  composeEnhancers(applyMiddleware(...middlewares))
);
const persistor = persistStore(store);

// Used to clean the storage that uses Redux Persist.
// persistor.purge();

sagaMiddleware.run(rootSaga);

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(authActions.logout('Expiró la sesión'));
    }
  }
);

export default { store, persistor };
