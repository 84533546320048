// @flow
import * as React from 'react';
import FloatingLabel from 'floating-label-react';
import 'floating-label-react/styles.css';
import InputWrapper from './Input.style';
import type { InputProps } from './Input.types';
import iconAlert from '../../../assets/icons/icon-alert.svg';

const Input = (props: InputProps) => {
  const { leftIcon, showAlert, ...rest } = props;

  return (
    <InputWrapper>
      <div className="input-line">
        {leftIcon ? 
          (
            <div className="input-img-container">
              <img className="input-img" alt="" src={leftIcon} />
            </div>
          ) : null }
        <FloatingLabel {...rest} className={showAlert ? 'input-error' : ''} />
        <img className={showAlert ? 'alert-img' : 'alert-img-hide'} alt="" src={iconAlert} />
      </div>
    </InputWrapper>
  );
};

export default Input;
