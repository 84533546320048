import types from './types';

const initialState = {
  bankRecords: [], // bankRecords with positive balance
  bankRecordsPaginationSize: 0,
  internalReceipt: null,
  selectedClient: null,
  positiveBalance: 0,
  // interactions - inputs
  form: {
    selectedBankRecord: { id: null },
    selectedFeeInvoices: [],
    additionalDataFiles: [],
    bankRecordAvAmount: 0
  },

  // flags
  successGetBankRecords: null,
  successSendConfirmation: null,
  loadingSendConfirmation: false
};
export default (state = initialState, action) => {
  const { type, payload = null } = action;
  switch (type) {
    case types.GET_BANK_RECORDS_WITH_POSITIVE_BALANCE_SUCCESS: {
      const { bankRecords, paginationSize } = payload;
      return {
        ...state,
        successGetBankRecords: true,
        bankRecords,
        bankRecordsPaginationSize: paginationSize
      };
    }
    case types.GET_BANK_RECORDS_WITH_POSITIVE_BALANCE_FAILED: {
      return { ...state, successGetBankRecords: false };
    }
    case types.SET_ASSIGN_BANK_RECORD_POSITIVE_BALANCE_SET_FORM: {
      return { ...state, form: { ...state.form, ...payload } };
    }
    case types.ASSIGN_BANK_RECORD_POSITIVE_BALANCE_SEND_CONFIRMATION_REQUEST: {
      return { ...state, loadingSendConfirmation: true };
    }
    case types.ASSIGN_BANK_RECORD_POSITIVE_BALANCE_SEND_CONFIRMATION_SUCCESS: {
      return {
        ...state,
        successSendConfirmation: true,
        loadingSendConfirmation: false
      };
    }
    case types.ASSIGN_BANK_RECORD_POSITIVE_BALANCE_SEND_CONFIRMATION_FAILED: {
      return { ...state, successSendConfirmation: false, loadingSendConfirmation: false };
    }
    case types.SET_INTERNAL_RECEIPT_WITH_POSITIVE_BALANCE: {
      const { internalReceipt, clientGroup, positiveBalance } = payload;
      return {
        ...state,
        internalReceipt,
        selectedClient: clientGroup,
        positiveBalance,
        form: {
          selectedBankRecord: { id: null },
          selectedFeeInvoices: [],
          additionalDataFiles: [],
          bankRecordAvAmount: 0
        }
      };
    }
    case types.ASSIGN_BANK_RECORD_POSITIVE_BALANCE_RESET_STATE: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
