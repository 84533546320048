// @flow
import type { StatelessFunctionalComponent } from 'react';
import styled from 'styled-components';

const Wrapper: StatelessFunctionalComponent<*> = styled.div`
  .input-error {
    width: 100%;
    font-size: 15px;
    position: relative;
    background-color: var(--white-color) !important;
    padding: 4px 4px;
    box-shadow: var(--white-color) !important;
    border-bottom: 1px solid var(--input-error-color) !important;
  }

  .input-line {
    display: flex;
    padding: 5px 0px;
    position: relative;
    opacity: 1 !important;
    label > input {
      padding-left: 8px;
      padding-top: 20px;
    }
    label > span {
      padding-left: 8px;
      font-size: 1.5em;
      color: var(--text-color-2);
    }
  }

  .input-img-container {
    margin: 16px 12px 12px 0px;
    width: 30px;
    height: 25px;
  }

  .input-img {
    width: 25px;
    height: 25px;
  }

  .alert-img {
    width: 25px;
    height: 25px;
    align-self: center;
    padding-bottom: 6px;
    position: absolute;
    right: 0;
  }

  .alert-img-hide {
    display: none;
  }

  .sel {
    width: 100%;
    font-size: 15px;
    position: relative;
    /* background-color: var(--white-color) !important; */
    background-color: transparent !important;
    opacity: 1 !important;
    border-bottom: 1px solid var(--text-color-2) !important;
    padding: 4px 4px;
    box-shadow: var(--white-color) !important;
    z-index: 100 !important;
  }
`;

export default Wrapper;
