export default {
  CLEAR_REGISTERSELLERRECEIPT_STATE: 'CLEAR_REGISTERSELLERRECEIPT_STATE',
  SET_SELLER_RECEIPT_CLIENT: 'SET_SELLER_RECEIPT_CLIENT',
  SET_SELECTED_CLIENT: 'SET_SELECTED_CLIENT',
  RESET_CONFIRMATION_RESULT: 'RESET_CONFIRMATION_RESULT',
  GET_CLIENT_VERIFICATION: 'GET_CLIENT_VERIFICATION',
  ADD_STATE: 'ADD_STATE',
  CLEAR_CLIENT_VERIFICATION: 'CLEAR_CLIENT_VERIFICATION',

  ADD_PAYMENT_METHOD: 'ADD_PAYMENT_METHOD',
  CREATE_SELLER_RECEIPT: 'CREATE_SELLER_RECEIPT',
  // Messages
  AN_ERROR_HAS_OCURRED: 'Ha ocurrido un error, vuelva a intentarlo más tarde.'
};
