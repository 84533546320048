import statusTypes from './types';
import registerBankInvoiceTypes from '../registerBankInvoice/types';

export const initialState = {
  isLoading: false,
  errorMessage: null,
  resultMessage: null,
  records: [],
  paginationSize: 0,
  sendConfirmation: false,
  successFinish: false,
  ucfeResponse: undefined,
  loadingAdenda: false,
  adenda: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case statusTypes.CLEAR_BANKRECORDS_STATE: {
      return { ...initialState };
    }
    case statusTypes.BANK_RECORD_UPLOAD_REQUEST: {
      return {
        ...state,
        errorMessage: null,
        isLoading: true
      };
    }
    case statusTypes.BANK_RECORD_UPLOAD_COBRANZA: {
      return {
        ...state,
        errorMessage: null,
        isLoading: true
      };
    }
    case statusTypes.BANK_RECORD_COBRANZA_SUCCESS: {
      return {
        ...state,
        resultMessage: action.payload.message,
        isLoading: false
      };
    }
    case statusTypes.BANK_RECORD_COBRANZA_FAILED: {
      return {
        ...state,
        errorMessage: statusTypes.AN_ERROR_HAS_OCURRED,
        isLoading: true
      };
    }
    case statusTypes.BANK_RECORD_SUCCESS_UPLOAD: {
      return {
        ...state,
        resultMessage: action.payload.message,
        isLoading: false
      };
    }
    case statusTypes.BANK_RECORD_FAILED_UPLOAD: {
      return {
        ...state,
        errorMessage: statusTypes.AN_ERROR_HAS_OCURRED,
        isLoading: false
      };
    }
    case statusTypes.CLIENT_REQUEST_SUCCESS: {
      return {
        ...state,
        clients: action.payload.clients,
        paginationSize: action.payload.paginationSize,
        isLoading: false
      };
    }
    case statusTypes.CLIENT_REQUEST_FAILED: {
      return {
        ...state,
        clients: [],
        paginationSize: initialState.paginationSize,
        errorMessage: statusTypes.AN_ERROR_HAS_OCURRED,
        isLoading: false
      };
    }
    case statusTypes.GET_BANK_RECORDS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        resultMessage: null,
        successFinish: false
      };
    }
    case statusTypes.SUCCESS_GET_BANK_RECORDS: {
      return {
        ...state,
        resultMessage: statusTypes.SUCCESS,
        records: action.payload.records,
        paginationSize: action.payload.paginationSize,
        isLoading: false
      };
    }
    case statusTypes.FAILED_GET_BANK_RECORDS: {
      return {
        ...state,
        errorMessage: statusTypes.AN_ERROR_HAS_OCURRED,
        isLoading: false
      };
    }
    case statusTypes.DELETE_BANK_RECORD_SUCCESS: {
      let recordIndex = state.records.findIndex((br) => br.id === action.payload.id);
      let records = state.records;
      records[recordIndex] = {
        ...records[recordIndex],
        deleted: true
      };
      return {
        ...state,
        errorMessage: null,
        isLoading: false,
        records,
        resultMessage: action.payload.message
      };
    }
    case statusTypes.DELETE_BANK_RECORD_FAILED: {
      return {
        ...state,
        errorMessage: statusTypes.AN_ERROR_HAS_OCURRED,
        isLoading: false,
        resultMessage: null
      };
    }
    case statusTypes.RESET_STATE: {
      return initialState;
    }
    case statusTypes.SEND_CONFIRMATION: {
      return {
        ...state,
        sendConfirmationResult: null,
        isLoading: true
      };
    }
    case statusTypes.CONFIRMATION_SUCCESS: {
      return {
        ...state,
        sendConfirmationResult: action.payload.receipt,
        ucfeResponse: action.payload.ucfeResponse,
        successFinish: true,
        isLoading: false
      };
    }
    case statusTypes.CONFIRMATION_FAIL: {
      return {
        ...state,
        isLoading: false
      };
    }
    case statusTypes.GENERATE_ADENDA: {
      return {
        ...state,
        loadingAdenda: true
      };
    }
    case statusTypes.GENERATE_ADENDA_SUCCESS: {
      return {
        ...state,
        adenda: action.payload.adenda,
        loadingAdenda: false
      };
    }
    case statusTypes.GENERATE_ADENDA_FAIL: {
      return {
        ...state,
        loadingAdenda: false
      };
    }
    case registerBankInvoiceTypes.RESET_CONFIRMATION_RESULT: {
      return {
        ...state,
        sendConfirmationResult: null
      };
    }
    case statusTypes.CLIENT_ACCOUNT_SUCCESS: {
      return {
        ...state,
        clientAccounts: action.payload.clients,
        paginationSize: action.payload.paginationSize,
        isLoading: false
      };
    }
    case statusTypes.CLIENT_ACCOUNT_FAILED: {
      return {
        ...state,
        clientAccounts: [],
        paginationSize: initialState.paginationSize,
        errorMessage: statusTypes.AN_ERROR_HAS_OCURRED,
        isLoading: false
      };
    }
    default:
      return state;
  }
};
