import styled from 'styled-components';

const ModalWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.75);
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  &.Modal {
    &--small {
      .Modal-inner {
        max-width: 400px;
      }
    }
  }

  .Modal {
    &-inner {
      background-color: #fff;
      max-height: 90vh;
      max-width: 800px;
      padding: 1.5rem;
      width: 90%;
      border-radius: 0px;
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
      overflow-y: auto;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    &-title {
      font-size: 28px;
      color: var(--text-color-2);
      font-weight: 500;
      font-family: var(--font-family-alt);
    }

    &-close {
      background-color: #eee;
      color: #000;
      border: 0;
      line-height: 1;
      font-size: inherit;
      font-family: inherit;
      font-weight: 500;
      cursor: pointer;
      padding: 10px;
      height: 38px;
      width: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: background-color 0.25s ease, color 0.25s ease;
      font-size: 16px;

      &:hover {
        background-color: var(--active-button);
        color: #fff;
      }
    }

    &-body {
      font-size: 16px;

      p {
        margin-bottom: 1rem;
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  ${(props) =>
    props.isOpen &&
    `
    display: flex;
  `}
`;

export default ModalWrapper;
