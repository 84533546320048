import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import bankRecordsSaga from './bankRecords/saga';
import bankInvoicesSaga from './bankInvoices/saga';
import registerSeller from './registerSellerReceipt/saga';
import accountSaga from './account/saga';
import assignBankRecordsPositiveBalance from './assignBankRecordsPositiveBalance/saga';
import sendAccountabilitySaga from './sendAccountability/saga';
import accountabilitiesSaga from './accountabilities/saga';
import configurationsSaga from './configurations/saga';

const rootSaga = function* rootSaga() {
  yield all([
    authSaga(),
    bankRecordsSaga(),
    bankInvoicesSaga(),
    accountSaga(),
    assignBankRecordsPositiveBalance(),
    registerSeller(),
    sendAccountabilitySaga(),
    accountabilitiesSaga(),
    configurationsSaga()
  ]);
};

export default rootSaga;
