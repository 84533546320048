import statusTypes from './types';

export const initialState = {
  isLoading: false,
  selectedClient: null,
  bankRecordAvAmount: null,
  errorMessage: null,
  clientVerification: null,
  userNotAllowed: null,
  hrVerification: false,
  selectedFeeInvoices: [],
  balanceOnAccount: 0,
  successFinish: false,
  paymentMethods: {
    check: [],
    creditCard: [],
    cash: [],
    deposit: []
  },
  fromAccountState: false,
  refresh: false
};

export default (state = initialState, action = { type: '', payload: undefined }) => {
  switch (action.type) {
    case statusTypes.CLEAR_REGISTERSELLERRECEIPT_STATE: {
      return { ...initialState };
    }
    case statusTypes.SET_SELLER_RECEIPT_CLIENT: {
      const { type: sellerType, value: sellerValue } = action.payload;
      return {
        ...state,
        [sellerType]: sellerValue
      };
    }
    case statusTypes.SET_SELECTED_CLIENT: {
      return {
        ...state,
        selectedClient: action.payload.client
      };
    }
    case statusTypes.RESET_CONFIRMATION_RESULT: {
      return initialState;
    }
    case statusTypes.ADD_STATE: {
      return {
        ...state,
        ...action.payload
      };
    }
    case statusTypes.GET_CLIENT_VERIFICATION: {
      return {
        ...state,
        ...action.payload,
        clientVerification: null
      };
    }
    case statusTypes.ADD_PAYMENT_METHOD: {
      const {
        payload: { paymentMethod, key }
      } = action;
      const id = state.paymentMethods[key].length;
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          [key]: [...state.paymentMethods[key], { ...paymentMethod, id }]
        }
      };
    }
    case statusTypes.CREATE_SELLER_RECEIPT: {
      return {
        ...state,
        isLoading: true
      };
    }
    case statusTypes.CLEAR_CLIENT_VERIFICATION: {
      return {
        ...state,
        clientVerification: null,
        hrVerification: false
      };
    }
    default:
      return state;
  }
};
