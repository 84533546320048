// @flow
import React from 'react';
import NavBar from '../screens/NavBar';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import type { ProtectedRouteProps } from './Router.types';

const ProtectedRoute = ({ children, availableAreas = null }: ProtectedRouteProps) => {
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  if (isLoggedIn) {
    if (user?.Area && availableAreas != null && availableAreas.includes(user?.Area?.id)) {
      return (
        <>
          <NavBar />
          {children}
        </>
      );
    }
    return <Redirect to="/home" />;
  }
  return <Redirect to="/login" />;
};

export default ProtectedRoute;
