// @flow
import type { StatelessFunctionalComponent } from 'react';
import styled from 'styled-components';

const InfoWrapper: StatelessFunctionalComponent<*> = styled.div`
  text-align: left !important;
  display: flex;
  align-items: center;
  font-size: 10px !important;

  .Info {
    &-icon {
      margin-right: 1.2em;
      width: 28px;
      height: auto;
    }

    &-value {
      font-size: 1.8em;
      font-weight: 500;
      color: #787879;
    }

    &-label {
      font-size: 1.4em;
      color: #4c4e55;
    }
  }

  ${props => props.medium && (`
    .Info {
      &-icon {
        width: auto;
        height: 42px;
      }

      &-value {
        font-size: 24px !important;
      }

      &-label {
        font-size: 16px !important;
      }
    }
  `)}
`;

export default InfoWrapper;
