import { takeLatest, call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { Accountabilities } from '../../api';

import * as actions from './actions';
import types from './types';

export function* getAccountabilities({ payload }) {
  try {
    const { page = 0, ...payloadFilters } = payload;
    const pageSize = 10;
    const { filters } = yield select(state=> state.accountabilities);
    
    const { success, accountabilities, paginationSize } = yield call(Accountabilities.getAll, {
      page,
      pageSize,
      ...filters,
      ...payloadFilters
    });

    if (success) yield put(actions.getAccountabilitiesSuccess({ accountabilities, paginationSize }));
    else yield put(actions.getAccountabilitiesFailed());
  } catch (e) {
    yield put(actions.getAccountabilitiesFailed());
  }
}

export function* getAccountabilityDetail({ payload, cb  }){
  try{
    const { id = null }  = payload;
    if(id){
      const { success, message = '', accountability } = yield call(Accountabilities.getAccountability, id);

      if(success) {
        const accountabilityValue = accountability;
        accountabilityValue.commentaries = accountability.commentaries ? accountability.commentaries : '';
        yield put(actions.getAccountabilityDetailSuccess(accountabilityValue));
      }
      
      yield call(cb, { success, message });
    } else {
      yield call(cb, { success: false });
    }
  }catch(e){
    yield call(cb, { success: false });
  }
}

export function* updateAccountabilityDetail({ payload, cb }) {
  try{
    const { accountabilityDetail } = yield select((state)=>state.accountabilities);
    const { success, message } = yield call(Accountabilities.updateAccountabilityDetail, payload);
    if(success)
    {
      yield put(actions.getAccountabilityDetail({ id: accountabilityDetail.id }, ()=>{}));
      yield call(toast.success, 
          payload?.approve ?
          `Ha sido aprobada exitosamente la rendición ${accountabilityDetail?.id ??''}` 
          : 'Se ha guardado la rendición.'
        , {
        position: toast.POSITION.BOTTOM_LEFT
      });

    }
    else 
      yield call(toast.error, message ?? 'Ha ocurrido un error inesperado', {
        position: toast.POSITION.BOTTOM_LEFT
      });


    yield call(cb)
  }catch(e){
    yield call(toast.error, 'Ha ocurrido un error inesperado', {
      position: toast.POSITION.BOTTOM_LEFT
    });
    yield call(cb)
  }
}

export default function* accountabilitiesSaga() {
  yield takeLatest(types.GET_ACCOUNTABILITIES_REQUEST, getAccountabilities);
  yield takeLatest(types.GET_ACCOUNTABILITY_DETAIL_REQUEST, getAccountabilityDetail);
  yield takeLatest(types.UPDATE_ACCOUNTABILITY_DETAIL_REQUEST, updateAccountabilityDetail);
}
