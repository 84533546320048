// @flow
import Axios from 'axios';

const serverUrl = process.env.REACT_APP_SERVER_URL || '';

const axios = Axios.create({
  baseURL: serverUrl
});

const setAccessToken = (accessToken: string) => {
  localStorage.setItem('wurth_access_token', accessToken);
};

const clearAccessToken = () => {
  localStorage.removeItem('wurth_access_token');
};

axios.interceptors.request.use(async (req) => {
  const token = localStorage.getItem('wurth_access_token');
  if (token) {
    req.headers.Authorization = `bearer ${token}`;
  }
  return req;
});

export default axios;
export {
  setAccessToken,
  clearAccessToken
};
