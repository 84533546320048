import React from 'react';

import iconAlert from '../../../assets/icons/icon-alert.svg';

const EmptyMessage = (props: { message: string }) => {
  const { message } = props;
  return (
    <div className="d-flex align-items-center mb-3">
      <img src={iconAlert} alt="" width={32} style={{ marginRight: 20 }} />
      <div className="f-21 text-gray">{message}</div>
    </div>
  );
};

export default EmptyMessage;
