// @flow
import * as React from 'react';
import LoaderWrapper from './Loader.style';
import type { LoaderProps } from './Loader.types';

const Loader = (props: LoaderProps) => (
  <LoaderWrapper {...props} aria-label="Cargando…">
    <div />
    <div />
    <div />
    <div />
    <div />
  </LoaderWrapper>
);

export default Loader;
