// @flow
import type { StatelessFunctionalComponent } from 'react';
import styled from 'styled-components';

const InputWrapper: StatelessFunctionalComponent<*> = styled.div`
  input {
    background-color: transparent;
    font-family: inherit;
  }

  .input-error {
    input {
      border-bottom: 1px solid var(--input-error-color) !important;
    }
    span {
      color: var(--input-error-color) !important;
    }
  }

  .input-line {
    display: flex;
    padding: 5px 0px;
    position: relative;
    label > input {
      padding-left: 8px;
      padding-top: 20px;
    }
    label > span {
      padding-left: 8px;
      font-size: 1.5em;
      color: var(--text-color-2);
    }
  }

  .input-img-container {
    margin: 16px 12px 12px 0px;
    width: 30px;
    height: 25px;
  }

  .input-img {
    width: 25px;
    height: 25px;
  }

  .alert-img {
    width: 25px;
    height: 25px;
    align-self: center;
    padding-bottom: 6px;
    position: absolute;
    right: 0;
  }

  .alert-img-hide {
    display: none;
  }
`;

export default InputWrapper;
