// @flow
import type { Saga } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import statusTypes from './types';
import bankRecordsActions from './actions';
import { BankRecords, Clients } from '../../api';
import { checkDates } from '../../helpers/dataValidator';

export function* uploadBankRecords({ payload: { rowsGetted, bank } }: { payload: Object }): Saga {
  try {
    if (rowsGetted.length > 0 && bank.length > 0) {
      let form = new FormData();
      form.append('records', JSON.stringify(rowsGetted));
      form.append('bank', bank);

      const { data } = yield call(BankRecords.uploadRecords, form);
      if (data.success) {
        yield put(bankRecordsActions.uploadRecordsSuccess({ message: statusTypes.SUCCESS }));
      } else {
        yield put(bankRecordsActions.uploadRecordsFailed({ message: statusTypes.AN_ERROR_HAS_OCURRED }));
      }
    } else {
      yield put(bankRecordsActions.uploadRecordsFailed({ message: statusTypes.AN_ERROR_HAS_OCURRED }));
    }
  } catch (error) {
    yield put(bankRecordsActions.uploadRecordsFailed({ message: statusTypes.AN_ERROR_HAS_OCURRED }));
  }
}
export function* uploadCobranzas({ payload: { rowsGetted } }: { payload: Object }): Saga {
  try {
    if (rowsGetted.length > 0) {
      let form = new FormData();
      rowsGetted[0] = rowsGetted[0].map((row)=>{
      
        return {
          zona: row['zona'],
          nombre: row['nombre'],
          equipo: row['equipo_1'],
          plan_cobro: row['plan cobro'],
          cobrado: row['cobrado'],
          porcentaje: row['% plan'],
          correo: row['mail territorio'],
        }
      })
      form.append('records', JSON.stringify(rowsGetted));
      const { data } = yield call(BankRecords.uploadCobranzas, form);
      if (data.success) {
        yield put(bankRecordsActions.uploadCobranzaSuccess({ message: statusTypes.SUCCESS }));
      } else {
        yield put(bankRecordsActions.uploadCobranzaFailed({ message: statusTypes.AN_ERROR_HAS_OCURRED }));
      }
    } else {
      yield put(bankRecordsActions.uploadCobranzaFailed({ message: statusTypes.AN_ERROR_HAS_OCURRED }));
    }
  } catch (error) {
    yield put(bankRecordsActions.uploadCobranzaFailed({ message: statusTypes.AN_ERROR_HAS_OCURRED }));
  }
}
export function* getRecords({ payload: { filters, currentPage } }: { payload: Object }): Saga {
  try {
    const dateFilters = yield call(
      checkDates,
      ['bank_transaction_date', 'import_dates_range'],
      Object.assign({}, filters)
    );
    const page = currentPage || 0;
    const filtersPR = {
      ...(filters.amount_range[0] > 0 &&
        filters.amount_range[1] > 0 && {
          amount_range: filters.amount_range
        }),
      ...(filters.description_filter.length > 0 && {
        description_filter: filters.description_filter
      }),
      ...(filters.bank_filter.length > 0 && {
        bank_filter: filters.bank_filter
      }),
      bank_records_in_use: filters.bank_records_in_use,
      ...dateFilters.data
    };

    const { data } = yield call(BankRecords.getRecords, { params: { page, filters: filtersPR } });
    if (data.success) {
      const { records, paginationSize } = data;
      yield put(bankRecordsActions.getRecordsSuccess({ records, paginationSize }));
    } else {
      yield put(bankRecordsActions.getRecordsFails({}));
    }
  } catch (e) {
    yield put(bankRecordsActions.getRecordsFails({}));
  }
}
export function* sendRecords({ payload: { filters, currentPage } }: { payload: Object }): Saga {
  try {
    const dateFilters = yield call(
      checkDates,
      ['bank_transaction_date', 'import_dates_range'],
      Object.assign({}, filters)
    );
    const page = currentPage || 0;
    const filtersPR = {
      ...(filters.amount_range[0] > 0 &&
        filters.amount_range[1] > 0 && {
          amount_range: filters.amount_range
        }),
      ...(filters.description_filter.length > 0 && {
        description_filter: filters.description_filter
      }),
      ...(filters.bank_filter.length > 0 && {
        bank_filter: filters.bank_filter
      }),
      bank_records_in_use: filters.bank_records_in_use,
      ...dateFilters.data
    };

    const { data } = yield call(BankRecords.sendRecords, { params: { page, filters: filtersPR } });
    if (data.success) {
      //yield put(bankRecordsActions.sendRecordsSuccess({ records, paginationSize }));
      yield put(bankRecordsActions.sendRecordsSuccess());
    } else {
      yield put(bankRecordsActions.sendRecordsFails({}));
    }
  } catch (e) {
    yield put(bankRecordsActions.sendRecordsFails({}));
  }
}

export function* getClients({ payload: { name, doc, docType, clientId, page } }: { payload: Object }): Saga {
  try {
    const filters = {
      ...(name !== undefined &&
        name.length > 0 && {
          name
        }),
      ...(doc !== undefined &&
        doc.length > 0 && {
          doc
        }),
      ...(docType !== undefined &&
        docType.text !== undefined &&
        docType.text.length > 0 && {
          doc_type: docType.text
        }),
      ...(clientId !== undefined &&
        clientId.length > 0 && {
          id: clientId
        }),
      ...(page !== undefined && {
        page
      })
    };

    const { data } = yield call(Clients.getClients, filters);

    if (data.success) {
      yield put(bankRecordsActions.getClientsSuccess({ clients: data.clients, paginationSize: data.paginationSize }));
    } else {
      yield put(bankRecordsActions.getClientsFailed({ message: statusTypes.AN_ERROR_HAS_OCURRED }));
    }
  } catch (error) {
    yield put(bankRecordsActions.getClientsFailed({ message: statusTypes.AN_ERROR_HAS_OCURRED }));
  }
}

export function* sendConfirmation({
  payload: { bankRecordId, selectedFeeInvoices, selectedClient, additionalDataFiles, adenda }
}: {
  payload: Object
}): Saga {
  try {
    if (bankRecordId !== undefined) {
      // send files
      let ids = '';
      if (additionalDataFiles !== undefined) {
        const formData = new FormData();
        additionalDataFiles.forEach((additionalDataFile) => {
          formData.append('files', additionalDataFile);
        });

        const { data } = yield call(BankRecords.sendFiles, formData);
        if (data.success) {
          data.files.forEach((file, index) => {
            if (data.files.length - 1 === index) {
              ids += file.id;
            } else {
              ids += file.id + ',';
            }
          });
        }
      }

      // send confirmation
      const { data } = yield call(BankRecords.sendConfirmation, {
        params: { bankRecordId, selectedFeeInvoices, selectedClient, ids, adenda }
      });

      if (data.success) {
        yield put(bankRecordsActions.confirmationSuccess({ receipt: data.receipt, ucfeResponse: data.ucfeResponse }));
      } else {
        yield call(toast.error, 'Ocurrió un error y no se pudo crear el recibo interno', {
          position: toast.POSITION.BOTTOM_LEFT
        });
        if (data.ucfeMessage?.length) {
          yield call(toast.error, data.ucfeMessage, {
            position: toast.POSITION.BOTTOM_LEFT
          });
        }
        yield put(bankRecordsActions.confirmationFailed({ message: statusTypes.AN_ERROR_HAS_OCURRED }));
      }
    } else {
      yield put(bankRecordsActions.confirmationFailed({ message: statusTypes.AN_ERROR_HAS_OCURRED }));
    }
  } catch (error) {
    yield put(bankRecordsActions.confirmationFailed({ message: statusTypes.AN_ERROR_HAS_OCURRED }));
  }
}

export function* generateAdendaSaga({
  payload: { bankRecordId, selectedFeeInvoices, paymentMethods , selected = undefined}
}: {
  payload: Object
}): Saga {
  try {
    if (bankRecordId !== undefined) {
      const payments = paymentMethods || null;

      // generate Adenda
      const { data } = yield call(BankRecords.generateAdenda, {
        params: { bankRecordId, selectedFeeInvoices, paymentMethods: payments, selected }
      });

      if (data.success) {
        yield put(bankRecordsActions.generateAdendaSuccess({ adenda: data.adenda }));
      } else {
        yield call(toast.error, 'Ocurrió un error y no se pudo generar la Adenda', {
          position: toast.POSITION.BOTTOM_LEFT
        });        
        yield put(bankRecordsActions.generateAdendaFailed({ message: statusTypes.AN_ERROR_HAS_OCURRED }));
      }
    } else {
      yield put(bankRecordsActions.generateAdendaFailed({ message: statusTypes.AN_ERROR_HAS_OCURRED }));
    }
  } catch (error) {
    yield put(bankRecordsActions.generateAdendaFailed({ message: statusTypes.AN_ERROR_HAS_OCURRED }));
  }
}

export function* deleteBankRecord({ payload: { id } }: { payload: Object }): Saga {
  try {
    const { data } = yield call(BankRecords.deleteRecord, { bankRecord_id: id });
    if (data.success) {
      yield put(bankRecordsActions.deleteRecordSuccess(id));
    } else {
      yield put(bankRecordsActions.deleteRecordFailed(id));
    }
  } catch (e) {
    yield put(bankRecordsActions.getClientsFailed({ message: statusTypes.AN_ERROR_HAS_OCURRED }));
  }
}

export function* getClientsAccounts({ payload: { name, doc, docType, clientId, page, userId } }: { payload: Object }): Saga {
  try {
    
    const filters = {
      ...(name !== undefined &&
        name.length > 0 && {
          name
        }),
      ...(doc !== undefined &&
        doc.length > 0 && {
          doc
        }),
      ...(docType !== undefined &&
        docType.text !== undefined &&
        docType.text.length > 0 && {
          doc_type: docType.text
        }),
      ...(clientId !== undefined &&
        clientId.length > 0 && {
          id: clientId
        }),
      ...(page !== undefined && {
        page
      }), 
      ...({ userId }),
    };

    const { data } = yield call(Clients.getClientAccounts, filters);

    if (data.success) {
      yield put(bankRecordsActions.getClientsAccountsSuccess({ clients: data.clients, paginationSize: data.paginationSize }));
    } else {
      yield put(bankRecordsActions.getClientsAccountsFailed({ message: statusTypes.AN_ERROR_HAS_OCURRED }));
    }
  } catch (error) {
    yield put(bankRecordsActions.getClientsAccountsFailed({ message: statusTypes.AN_ERROR_HAS_OCURRED }));
  }
}

export default function* bankRecordsSaga(): Saga {
  yield takeLatest(statusTypes.BANK_RECORD_UPLOAD_REQUEST, uploadBankRecords);
  yield takeLatest(statusTypes.BANK_RECORD_UPLOAD_COBRANZA, uploadCobranzas);
  yield takeLatest(statusTypes.GET_BANK_RECORDS_REQUEST, getRecords);
  yield takeLatest(statusTypes.SEND_BANK_RECORDS_REQUEST, sendRecords);
  yield takeLatest(statusTypes.CLIENT_REQUEST, getClients);
  yield takeLatest(statusTypes.SEND_CONFIRMATION, sendConfirmation);
  yield takeLatest(statusTypes.GENERATE_ADENDA, generateAdendaSaga);
  yield takeLatest(statusTypes.DELETE_BANK_RECORD_REQUEST, deleteBankRecord);
  yield takeLatest(statusTypes.CLIENT_ACCOUNT_REQUEST, getClientsAccounts);
}
