import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import authReducer from './auth/reducer';
import bankRecordsReducer from './bankRecords/reducer';
import bankInvoicesReducer from './bankInvoices/reducer';
import configurationsReducer from './configurations/reducer';
import registerBankInvoiceReducer from './registerBankInvoice/reducer';
import accountReducer from './account/reducer';
import assignBankRecordsPositiveBalanceReducer from './assignBankRecordsPositiveBalance/reducer';
import registerSellerReceiptReducer from './registerSellerReceipt/reducer';
import sendAccountabilityReducer from './sendAccountability/reducer';
import accountabilitiesReducer from './accountabilities/reducer';

const createRootReducer = (history) =>
  combineReducers({
    auth: authReducer,
    bankRecords: bankRecordsReducer,
    bankInvoices: bankInvoicesReducer,
    configurations: configurationsReducer,
    registerBankInvoice: registerBankInvoiceReducer,
    registerSellerReceipt: registerSellerReceiptReducer,
    account: accountReducer,
    assignBankRecordsPositiveBalance: assignBankRecordsPositiveBalanceReducer,
    sendAccountability: sendAccountabilityReducer,
    accountabilities: accountabilitiesReducer,
    router: connectRouter(history)
  });

export default createRootReducer;
