// @flow
import type { Saga } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import { push } from 'connected-react-router';

import { User, setAccessToken, clearAccessToken } from '../../api';
import authActions from './actions';
import configActions from '../configurations/actions';
import { loginTypes } from './types';
import { encodePassword, SECURITY_LIMIT } from '../../helpers';

export function* logoutSaga({ payload }: { payload: string }): Saga {
  try {
    yield call(clearAccessToken);
    yield put(authActions.logoutSuccess(payload));
  } catch (error) {
    yield put(authActions.logoutFailed(`Ocurrió un error al intentar borrar el access token. ${error}`));
  }
}

export function* loginRequestSaga({ payload }: { payload: Object }): Saga {
  try {
    if (!payload.token) {
      yield put(authActions.loginFailed('Fallo de seguridad, recargue la página y vuelva a intentarlo'));
      return;
    }

    const dataCaptcha = yield call(User.validateToken, {
      token: payload.token
    });
    if (dataCaptcha.success && dataCaptcha.score >= SECURITY_LIMIT) {
      const data = yield call(User.login, {
        username: payload.userName,
        passEncoded: encodePassword(payload.password)
      });

      if (data.success) {
        const { token, configurations } = data;
        yield call(setAccessToken, token);
        yield put(configActions.setConfigurations(configurations));
        const { data: user } = JSON.parse(decodeURIComponent(escape(atob(token.split('.')[1]))));

        yield put(authActions.loginSuccess({ ...payload, ...user }));
        yield put(push('/home'));
      } else {
        yield put(authActions.loginFailed(data.message));
      }
    } else {
      yield put(authActions.loginFailed('Fallo en validación de seguridad, vuelva a intentarlo más tarde'));
    }
  } catch (error) {
    yield put(authActions.loginFailed('Ocurrió un error al intentar ingresar.'));
  }
}

// SAGA para las acciones apenas re hidrata el State.
export function* rehydrateSaga(): Saga {
  try {
    const token = localStorage.getItem('wurth_access_token');
    const decoded = jwtDecode(token);
    const currentTime = new Date().getTime() / 1000;
    if (currentTime > decoded.exp) {
      /* expired */
      yield put(authActions.logout('Expiró la sesión'));
    }
  } catch (error) {
    // console.log('Ocurrió un error al intentar obtener los datos cliente.');
  }
}

export default function* authSaga(): Saga {
  yield takeLatest(loginTypes.LOGOUT, logoutSaga);
  yield takeLatest(loginTypes.REQUEST, loginRequestSaga);
  yield takeLatest('persist/REHYDRATE', rehydrateSaga);
}
