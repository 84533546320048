import statusTypes from './types';

export default {
  setState: (payload) => ({
    type: statusTypes.SET_REGISTER_BANK_INVOICE_STATE,
    payload
  }),
  setSelectedClient: (payload) => ({
    type: statusTypes.SET_SELECTED_CLIENT,
    payload
  }),
  resetConfirmationResult: (payload) => ({
    type: statusTypes.RESET_CONFIRMATION_RESULT,
    payload
  }),
  addState: (payload) => ({
    type: statusTypes.ADD_REGISTER_BANK_INVOICE_STATE,
    payload
  }),
};
