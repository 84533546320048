export default {
  CLEAR_BANKINVOICES_STATE: 'CLEAR_BANKINVOICES_STATE',
  CREATE_BANK_INVOICE_REQUEST: 'CREATE_BANK_INVOICE_REQUEST',
  CREATE_BANK_INVOICE_SUCCESS: 'CREATE_BANK_INVOICE_SUCCESS',
  CREATE_BANK_INVOICE_FAIL: 'CREATE_BANK_INVOICE_FAIL',
  LOADING: 'LOADING',

  // Messages
  AN_ERROR_HAS_OCURRED: 'Ha ocurrido un error, vuelva a intentarlo más tarde.',
  SUCCESS_ANNULATION_RECEIPT: 'El recibo se ha anulado correctamente.',
  SUCCESS: 'SUCCESS',

  // invoices retrieve
  GET_BANK_INVOICES_REQUEST: 'GET_BANK_INVOICES_REQUEST',
  SUCCESS_GET_BANK_INVOICES: 'SUCCESS_GET_BANK_INVOICES',
  FAILED_GET_BANK_INVOICES: 'FAILED_GET_BANK_INVOICES',
    // invoices retrieve
  GET_PRE_INVOICES_REQUEST: 'GET_PRE_INVOICES_REQUEST',
  SUCCESS_GET_PRE_INVOICES: 'SUCCESS_GET_PRE_INVOICES',
  FAILED_GET_PRE_INVOICES: 'FAILED_GET_PRE_INVOICES',
  BANK_PRE_RECIBO_EMITIR: 'BANK_PRE_RECIBO_EMITIR',
  BANK_PRE_RECIBO_REJECT: 'BANK_PRE_RECIBO_REJECT',
  BANK_PRE_RECIBO_REJECT_SUCCESS: 'BANK_PRE_RECIBO_REJECT_SUCCESS',
  BANK_PRE_RECIBO_EMITIR_SUCCESS: 'BANK_PRE_RECIBO_EMITIR_SUCCESS',
  GET_PAYMENT_METHODS_REQUEST: 'GET_PAYMENT_METHODS_REQUEST',
  SUCCESS_GET_PAYMENTS: 'SUCCESS_GET_PAYMENTS',
  FAILED_GET_PAYMENTS: 'FAILED_GET_PAYMENTS',

  INVOICE_SHARE_PDF: 'INVOICE_SHARE_PDF',
  INVOICE_SHARE_PDF_SUCCESS: 'INVOICE_SHARE_PDF_SUCCESS',
  INVOICE_SHARE_PDF_INIT: 'INVOICE_SHARE_PDF_INIT',
  INVOICE_SHARE_PDF_FAILED: 'INVOICE_SHARE_PDF_FAILED',

  BANK_INVOICES_REQUEST: 'BANK_INVOICES_REQUEST',
  BANK_INVOICES_SUCCESS: 'BANK_INVOICES_SUCCESS',
  BANK_INVOICES_FAILED: 'BANK_INVOICES_FAILED',

  BANK_INVOICES_SHARE_PDF: 'BANK_INVOICES_SHARE_PDF',
  BANK_INVOICES_SHARE_PDF_SUCCESS: 'BANK_INVOICES_SHARE_PDF_SUCCESS',
  BANK_INVOICES_SHARE_PDF_INIT: 'BANK_INVOICES_SHARE_PDF_INIT',
  BANK_INVOICES_SHARE_PDF_FAILED: 'BANK_INVOICES_SHARE_PDF_FAILED',

  BANK_INVOICES_ANNULATION: 'BANK_INVOICES_ANNULATION',
  BANK_INVOICES_ANNULATION_SUCCESS: 'BANK_INVOICES_ANNULATION_SUCCESS',
  BANK_INVOICES_ANNULATION_INIT: 'BANK_INVOICES_ANNULATION_INIT',
  BANK_INVOICES_ANNULATION_FAILED: 'BANK_INVOICES_ANNULATION_FAILED',

  BANK_INVOICES_EMIT: 'BANK_INVOICES_EMIT',
  BANK_INVOICES_EMIT_SUCCESS: 'BANK_INVOICES_EMIT_SUCCESS',
  BANK_INVOICES_EMIT_INIT: 'BANK_INVOICES_EMIT_INIT',
  BANK_INVOICES_EMIT_FAILED: 'BANK_INVOICES_EMIT_FAILED',
  
  BANK_INVOICES_MOD_BANK: 'BANK_INVOICES_MOD_BANK',
  BANK_INVOICES_MOD_BANK_SUCCESS: 'BANK_INVOICES_MOD_BANK_SUCCESS',
  BANK_INVOICES_MOD_BANK_INIT: 'BANK_INVOICES_MOD_BANK_INIT',
  BANK_INVOICES_MOD_BANK_FAILED: 'BANK_INVOICES_MOD_BANK_FAILED',
  
  BANK_INVOICES_REMOVE_AMOUNT: 'BANK_INVOICES_REMOVE_AMOUNT',
  BANK_INVOICES_REMOVE_AMOUNT_SUCCESS: 'BANK_INVOICES_REMOVE_AMOUNT_SUCCESS',
  BANK_INVOICES_REMOVE_AMOUNT_INIT: 'BANK_INVOICES_REMOVE_AMOUNT_INIT',
  BANK_INVOICES_REMOVE_AMOUNT_FAILED: 'BANK_INVOICES_REMOVE_AMOUNT_FAILED',

  BANK_INVOICES_DECLINE: 'BANK_INVOICES_DECLINE',
  BANK_INVOICES_DECLINE_SUCCESS: 'BANK_INVOICES_DECLINE_SUCCESS',
  BANK_INVOICES_DECLINE_INIT: 'BANK_INVOICES_DECLINE_INIT',
  BANK_INVOICES_DECLINE_FAILED: 'BANK_INVOICES_DECLINE_FAILED',

  CHECK_AND_EMIT: 'CHECK_AND_EMIT',

  SEND_RECEIPTS_FILTERING: 'SEND_RECEIPTS_FILTERING',

  SET_STATE: 'SET_STATE'
};
