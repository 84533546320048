import { authTypes, loginTypes } from './types';

const authActions = {
  authResetError: () => ({
    type: authTypes.RESET_ERROR,
  }),
  authResetResultMessage: () => ({
    type: authTypes.RESET_RESULT_MESSAGE
  }),
  loginRequest: payload => ({
    type: loginTypes.REQUEST,
    payload
  }),
  loginSuccess: payload => ({
    type: loginTypes.SUCCESS,
    payload
  }),
  loginFailed: errorMessage => ({
    type: loginTypes.FAILED,
    payload: errorMessage
  }),
  logout: (payload: string) => ({
    type: loginTypes.LOGOUT,
    payload
  }),
  logoutSuccess: (payload: string) => ({
    type: loginTypes.LOGOUT_SUCCESS,
    payload
  }),
  logoutFailed: (payload) => ({
    type: loginTypes.LOGOUT_FAILED,
    payload
  })
};

export default authActions;
