export default {
  GET_ACCOUNTABILITIES_REQUEST: 'GET_ACCOUNTABILITIES_REQUEST',
  GET_ACCOUNTABILITIES_SUCCESS: 'GET_ACCOUNTABILITIES_SUCCESS',
  GET_ACCOUNTABILITIES_FAILED: 'GET_ACCOUNTABILITIES_FAILED',

  GET_ACCOUNTABILITY_DETAIL_REQUEST: 'GET_ACCOUNTABILITY_DETAIL_REQUEST',
  GET_ACCOUNTABILITY_DETAIL_SUCCESS: 'GET_ACCOUNTABILITY_DETAIL_SUCCESS',

  UPDATE_ACCOUNTABILITY_DETAIL_REQUEST: 'UPDATE_ACCOUNTABILITY_DETAIL_REQUEST'
};
