// @flow
import type { Saga } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import status from './types';
import actions from './actions';
import assignPositiveBalanceActions from '../assignBankRecordsPositiveBalance/actions';

import { BankRecords, Clients } from '../../api';

export function* getClientVerification({
  payload: { id, clientWurthId, clientGroupId, clientGroup }
}: {
  payload: Object
}): Saga {
  try {
    const { data } = yield call(BankRecords.getBankRecordsWithPositiveBalanceByClientGroup, {
      client_group_id: clientGroupId
    });
    //TODO: aca esta el control de asignar saldo
    if (data && data.success && data.internalReceipts.length === 0) {
      if (data.sellerNotAllowed){
        return yield put(actions.addState({ userNotAllowed: true, clientVerification: false, blocked: null, hrVerification: true, isLoading: false }));
      }
      const { data: timeData } = yield call(BankRecords.checkValidRegisterTime);
      if (timeData.success && timeData.valid) {
        // control de bloqueado
        const request = yield call(Clients.isClientBloqued, { clientWurthId, clientId: id });
        if (request.data.success) {
          // todo bien
          yield put(
            actions.addState({
              blocked: request.data.blocked,
              hrVerification: true,
              clientVerification: true,
              isLoading: false
            })
          );
        } else {
          // bloqueada
          yield put(
            actions.addState({ blocked: true, hrVerification: true, clientVerification: true, isLoading: false })
          );
        }
      } else {
        // hora invalida
        yield put(actions.addState({ hrVerification: false, clientVerification: true, isLoading: false }));
      }
    } else if (data && !data.success) {
      // error
      yield put(actions.addState({ errorMessage: status.AN_ERROR_HAS_OCURRED, isLoading: false }));
    } else {
      if (data.internalReceipts.length > 0) {
        const internalReceiptWithPositiveBalance = data.internalReceipts[0];

        let positiveBalance = 0;
        if (internalReceiptWithPositiveBalance.bankRecord) {
          positiveBalance = internalReceiptWithPositiveBalance.bankRecord.available_amount;
        }
        if (internalReceiptWithPositiveBalance.sellerReceipt) {
          positiveBalance = internalReceiptWithPositiveBalance.sellerReceipt.available_balance;
        }

        // Guardamos el Internal Receipt que tiene Saldo a Cuenta en Redux por si redirije a Asignar Saldo
        yield put(
          assignPositiveBalanceActions.setInternalReceiptWithPositiveBalance({
            internalReceipt: internalReceiptWithPositiveBalance,
            clientGroup,
            positiveBalance
          })
        );
      }

      // cliente sin verificar
      yield put(actions.addState({ clientVerification: false, blocked: null, hrVerification: true, isLoading: false }));
    }
  } catch (error) {
    yield put(actions.addState({ errorMessage: status.AN_ERROR_HAS_OCURRED }));
  }
}

export function* createSellerReceipt({
  payload: { receiptAmount, availableBalance, selectedFeeInvoices, selectedClient, adenda }
}: {
  payload: Object
}): Saga {
  try {
    const { data: timeData } = yield call(BankRecords.checkValidRegisterTime);
    if (timeData.success && timeData.valid) {
      const request = yield call(Clients.isClientBloqued, {
        clientWurthId: selectedClient.wurth_id,
        clientId: selectedClient.id
      });
      if (request.data.success) {
        if (request.data.blocked) {
          // bloqueada
          yield put(
            actions.addState({
              blocked: request.data.blocked,
              hrVerification: true,
              clientVerification: true,
              isLoading: false,
              refresh: true
            })
          );
        } else {
          const {
            cash: cashPaymentMethods,
            check: checkPaymentMethods,
            creditCard: creditCardPaymentMethods,
            deposit: depositPaymentMethods
          } = yield select((state) => state.registerSellerReceipt.paymentMethods);
          
          const { data } = yield call(BankRecords.createSellerReceipt, {
            params: {
              receiptAmount,
              availableBalance,
              selectedFeeInvoices,
              selectedClient,
              adenda,
              cashPaymentMethods,
              checkPaymentMethods,
              creditCardPaymentMethods,
              depositPaymentMethods
            }
          });

          if (data.success) {
            yield put(
              actions.addState({
                sendConfirmationResult: data.receipt,
                ucfeResponse: { ucfe_invoice_nro: data.ucfeInvoiceNro },
                successFinish: true,
                isLoading: false,
                fromAccountState: false
              })
            );
          } else {
            yield put(actions.addState({isLoading: false, errorMessage: data.message }));
          }
        }
      } else {
        yield put(actions.addState({ errorMessage: status.AN_ERROR_HAS_OCURRED }));
      }
    } else {
      // hora invalida
      yield put(actions.addState({ hrVerification: false, clientVerification: true, isLoading: false, refresh: true }));
    }
  } catch (error) {
    yield put(actions.addState({ errorMessage: status.AN_ERROR_HAS_OCURRED, isLoading: false }));
  }
}

export default function* registerSeller(): Saga {
  yield takeLatest(status.GET_CLIENT_VERIFICATION, getClientVerification);
  yield takeLatest(status.CREATE_SELLER_RECEIPT, createSellerReceipt);
}
