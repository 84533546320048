// @flow
import type { StatelessFunctionalComponent } from 'react';
import styled from 'styled-components';

const LoaderWrapper: StatelessFunctionalComponent<*> = styled.div`
  animation-delay: 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 20px !important;


  ${props => props.cover && `
    height: 100vh;
  `}

  > div {
    width: 1em;
    height: 1em;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(191, 191, 191, 0.6);
    margin: .35em !important;

    &:before {
      content: '';
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background-color: rgba(191, 191, 191, 0.8);
      opacity: 0.7;
      animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
      transition: 0.5s all ease;
      transform: scale(1);
    }
  }

  @keyframes scale {
    0% {
      transform: scale(1);
    }

    50%, 75% {
      transform: scale(2.5);
    }

    78%, 100% {
      opacity: 0;
    }
  }

  > div:nth-child(1):before { animation-delay: .2s; }
  > div:nth-child(2):before { animation-delay: .4s; }
  > div:nth-child(3):before { animation-delay: .6s; }
  > div:nth-child(4):before { animation-delay: .8s; }
  > div:nth-child(5):before { animation-delay: 1s; }

  ${props => props.medium && `
    font-size: 15px !important;
  `}

  ${props => props.small && `
    font-size: 10px !important;
  `}

  ${props => props.white && `
    > div {
      background-color: rgba(255, 255, 255, .6);

      &:before {
        background-color: rgba(255, 255, 255, .8);
      }
    }
  `}

  ${props => props.white && `
    > div {
      background-color: rgba(255, 255, 255, .8);

      &:before {
        background-color: rgba(255, 255, 255, 1);
      }
    }
  `}

  ${props => props.blue && `
    > div {
      background-color: rgba(4, 46, 135, .8);

      &:before {
        background-color: rgba(4, 46, 135, 1);
      }
    }
  `}

  ${props => props.yellow && `
    > div {
      background-color: rgba(255, 199, 41, .8);

      &:before {
        background-color: rgba(255, 199, 41, 1);
      }
    }
  `}

  ${props => props.short && `
    > div:nth-child(1),
    > div:nth-child(2) {
      display: none;
    }
  `}
`;

export default LoaderWrapper;
