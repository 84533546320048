// @flow
import * as React from 'react';
import CardWrapper from './Card.style';
import type { CardProps, HeaderProps } from './Card.types';

class Card extends React.Component<CardProps> {

  static Header = ({ children, className = '' }: HeaderProps): React.Element<*> => (
    <p className={`Card-header ${className}`}>
      {children}
    </p>
  );

  render() {
    const {
      children,
      className
    } = this.props;

    return (
      <CardWrapper className={className}>
        <div className="Card-container">
          <div className="Card-box">
            {children}
          </div>
        </div>
      </CardWrapper>
    );
  }
}

export default Card;
