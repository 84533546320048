// @flow
import type { StatelessFunctionalComponent } from 'react';
import styled from 'styled-components';

const BadgeWrapper: StatelessFunctionalComponent<*> = styled.span`
  font-size: 1.2em !important;
  min-width: 6.5em;
  border-radius: 999px;
  padding: .325em .75em .45em .75em !important;
  text-align: center;
  color: #fff !important;
  background-color: #bfbfbf;
  display: inline-block;
  line-height: 1 !important;
  font-weight: 500 !important;

  ${props => props.type === 'success' && (`
    background-color: #00c77c;
  `)}

  ${props => props.type === 'warning' && (`
    background-color: #ec9c64;
  `)}

  /* NUEVO */

  ${props => props.type === 'grey' && (`
    background-color: #53565A;
  `)}

  ${props => props.type === 'orange' && (`
    background-color: #ec9c64;
  `)}

  ${props => props.type === 'blue' && (`
    background-color: var(--blue-light-color);
  `)}

  ${props => props.type === 'blue2' && (`
    background-color: var(--blue-color);
  `)}

  ${props => props.type === 'green' && (`
    background-color: #00c77c;
  `)}

  ${props => props.type === 'yellow' && (`
    background-color: #FFCD00;
    color: #53565A;
  `)}

  ${props => props.type === 'red' && (`
    background-color: #f44336;
  `)}
`;

export default BadgeWrapper;
