import statusTypes from './types';

export default {
  getBankInvoices: (payload) => ({
    type: statusTypes.GET_BANK_INVOICES_REQUEST,
    payload
  }),
  getBankInvoicesSuccess: (payload) => ({
    type: statusTypes.SUCCESS_GET_BANK_INVOICES,
    payload
  }),
  getBankInvoicesFails: (payload) => ({
    type: statusTypes.FAILED_GET_BANK_INVOICES,
    payload
  }),
  getPreReceipt: (payload) => ({
    type: statusTypes.GET_PRE_INVOICES_REQUEST,
    payload
  }),
  getPreReceiptSuccess: (payload) => ({
    type: statusTypes.GET_PRE_INVOICES_REQUEST,
    payload
  }),
  getPreReceiptFails: (payload) => ({
    type: statusTypes.FAILED_GET_BANK_INVOICES,
    payload
  }),
  getPaymentMethodsRequest: (payload) => ({
    type: statusTypes.GET_PAYMENT_METHODS_REQUEST,
    payload
  }),
  getPaymentsSuccess: (payload) => ({
    type: statusTypes.SUCCESS_GET_PAYMENTS,
    payload
  }),
  getPaymentsFails: (payload) => ({
    type: statusTypes.FAILED_GET_PAYMENTS,
    payload
  }),
  searchBankInvoicesSuccess: (payload) => ({
    type: statusTypes.BANK_INVOICES_SUCCESS,
    payload
  }),
  getBankInvoicesFail: (payload) => ({
    type: statusTypes.BANK_INVOICES_FAILED,
    payload
  }),
  getBankInvoicesSearch: (payload) => ({
    type: statusTypes.BANK_INVOICES_REQUEST,
    payload
  }),
  postShareInvPDF: (payload) => ({
    type: statusTypes.INVOICE_SHARE_PDF,
    payload
  }),
  postShareInvPDFInit: (payload) => ({
    type: statusTypes.INVOICE_SHARE_PDF_INIT,
    payload
  }),
  postShareInvPDFSuccess: (payload) => ({
    type: statusTypes.INVOICE_SHARE_PDF_SUCCESS,
    payload
  }),
  postShareInvPDFFail: (payload) => ({
    type: statusTypes.INVOICE_SHARE_PDF_FAILED,
    payload
  }),
  postSharePDF: (payload) => ({
    type: statusTypes.BANK_INVOICES_SHARE_PDF,
    payload
  }),
  postSharePDFInit: (payload) => ({
    type: statusTypes.BANK_INVOICES_SHARE_PDF_INIT,
    payload
  }),
  postSharePDFSuccess: (payload) => ({
    type: statusTypes.BANK_INVOICES_SHARE_PDF_SUCCESS,
    payload
  }),
  postSharePDFFail: (payload) => ({
    type: statusTypes.BANK_INVOICES_SHARE_PDF_FAILED,
    payload
  }),
  postEmit: (payload) => ({
    type: statusTypes.BANK_INVOICES_EMIT,
    payload
  }),
  postEmitInit: (payload) => ({
    type: statusTypes.BANK_INVOICES_EMIT_INIT,
    payload
  }),
  postEmitSuccess: (payload) => ({
    type: statusTypes.BANK_INVOICES_EMIT_SUCCESS,
    payload
  }),
  postEmitFail: (payload) => ({
    type: statusTypes.BANK_INVOICES_EMIT_FAILED,
    payload
  }),
  postModifyBank: (payload) => ({
    type: statusTypes.BANK_INVOICES_MOD_BANK,
    payload
  }),
  postModifyBankInit: (payload) => ({
    type: statusTypes.BANK_INVOICES_MOD_BANK_INIT,
    payload
  }),
  postModifyBankSuccess: (payload) => ({
    type: statusTypes.BANK_INVOICES_MOD_BANK_SUCCESS,
    payload
  }),
  postModifyBankFail: (payload) => ({
    type: statusTypes.BANK_INVOICES_MOD_BANK_FAILED,
    payload
  }),
  postRemoveAmount: (payload) => ({
    type: statusTypes.BANK_INVOICES_REMOVE_AMOUNT,
    payload
  }),
  postRemoveAmountInit: (payload) => ({
    type: statusTypes.BANK_INVOICES_REMOVE_AMOUNT_INIT,
    payload
  }),
  postRemoveAmountSuccess: (payload) => ({
    type: statusTypes.BANK_INVOICES_REMOVE_AMOUNT_SUCCESS,
    payload
  }),
  postRemoveAmountFail: (payload) => ({
    type: statusTypes.BANK_INVOICES_REMOVE_AMOUNT_FAILED,
    payload
  }),
  postDecline: (payload) => ({
    type: statusTypes.BANK_INVOICES_DECLINE,
    payload
  }),
  postDeclineInit: (payload) => ({
    type: statusTypes.BANK_INVOICES_DECLINE_INIT,
    payload
  }),
  postDeclineSuccess: (payload) => ({
    type: statusTypes.BANK_INVOICES_DECLINE_SUCCESS,
    payload
  }),
  postDeclineFail: (payload) => ({
    type: statusTypes.BANK_INVOICES_DECLINE_FAILED,
    payload
  }),
  postAnnulation: (payload) => ({
    type: statusTypes.BANK_INVOICES_ANNULATION,
    payload
  }),
  postPreReciboEmitir: (payload) => ({
    type: statusTypes.BANK_PRE_RECIBO_EMITIR,
    payload
  }),
  postPreReciboReject: (payload) => ({
    type: statusTypes.BANK_PRE_RECIBO_REJECT,
    payload
  }),
  postAnnulationInit: (payload) => ({
    type: statusTypes.BANK_INVOICES_ANNULATION_INIT,
    payload
  }),
  postAnnulationSuccess: (payload) => ({
    type: statusTypes.BANK_INVOICES_ANNULATION_SUCCESS,
    payload
  }),
  setState: (payload) => ({
    type: statusTypes.SET_STATE,
    payload
  }),
  postCheckAndEmit: () => ({
    type: statusTypes.CHECK_AND_EMIT
  }),

  sendReceiptsFiltering: (payload) => ({
    type: statusTypes.SEND_RECEIPTS_FILTERING,
    payload
  }),
  
};
