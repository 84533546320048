import types from './types';

const initialState = {
  // form
  receipts: null,
  paginationSize: 0,
  allDeposits: [],
  postedAcc:[],
  // flags
  successGetReceipts: null,
  successSendAccountability: null,
  loading: false
};

export default function SendAccountabilityReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case 'GET_ACCOUNTABILITY_DEPOSITS': {
      return { ...state, loading: true };
    }
    case types.GET_ACCOUNTABILITY_DEPOSIT_SUCCESS: {
      const { allDeposits = [] } = payload;
      return {
        ...state,
        allDeposits
      };
    }
    
    case types.SEND_ACCOUNTABILITY_REQUEST: {
      return { ...state, loading: true };
    }
    case 'GET_POSTED_ACCOUNTABILITIES_SUCCESS':{
      const { postedAcc = [] } = payload;
      return {
        ...state,
        postedAcc
      };
    }
    case types.SEND_ACCOUNTABILITY_SUCCESS: {
      return {
        ...state,
        successSendAccountability: true,
        loading: false,
        deposits: []
      };
    }
    case types.SEND_ACCOUNTABILITY_FAILED: {
      return {
        ...state,
        successSendAccountability: false,
        loading: false
      };
    }

    case types.GET_ACCOUNTABILITY_RECEIPTS_SUCCESS: {
      const { receipts = [], paginationSize = 1 } = payload;
      return {
        ...state,
        successGetReceipts: true,
        receipts,
        paginationSize
      };
    }
    case 'GET_ACCOUNTABILITY_DEPOSIT_FAILED':{
      return {
        ...state,
        successGetReceipts: false
      };
    }
    case types.GET_ACCOUNTABILITY_RECEIPTS_FAILED: {
      return {
        ...state,
        successGetReceipts: false
      };
    }
    case "DELETE_DEPOSIT_REQUEST": {
      return {
        ...state,
        payload
      };
    }
    case types.ADD_DEPOSIT: {
      return {
        ...state,
        deposits: [...state.deposits, payload]
      };
    }
    case types.REMOVE_DEPOSIT: {
      let deposits = [...state.deposits];
      deposits = deposits.filter((sr) => sr !== deposits[payload]);
      return {
        ...state,
        deposits
      };
    }

    case types.SET_SEND_ACCOUNTABILITY_STATE: {
      const { key = '', value = '' } = payload;
      return { ...state, [key]: value };
    }
    default:
      return state;
  }
}
