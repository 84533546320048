import axios from '../axiosConfig';

export default {
  login: request => axios.post('/auth/login', request)
  .then(response => response.data),

  validateToken: request => axios.post('/auth/validateToken', request)
  .then(response => response.data),

};
