import statusTypes from './types';

import bankInvoicesStatusTypes from '../bankInvoices/types';

export const initialState = {
  bankRecordId: null,
  additionalDataFiles: [],
  selectedFeeInvoices: [],
  isLoading: false,
  selectedClient: null,
  bankRecordAvAmount: null,
  selected: null,
  allSelected:[]
};

export default (state = initialState, action = { type: '', payload: undefined }) => {
  
  switch (action.type) {
    case statusTypes.CLEAR_REGISTERBANKINVOICE_STATE: {
      return { ...initialState };
    }
    case bankInvoicesStatusTypes.CLEAR_BANKINVOICES_STATE: {
      return { ...state, selected: null };
    }
    case statusTypes.SET_REGISTER_BANK_INVOICE_STATE: {
      return { ...initialState, ...action.payload };
    }
    case statusTypes.SET_SELECTED_CLIENT: {
      return {
        ...state,
        selectedClient: action.payload.client
      };
    }
    case statusTypes.RESET_CONFIRMATION_RESULT: {
      return initialState;
    }
    case statusTypes.ADD_REGISTER_BANK_INVOICE_STATE: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
};
